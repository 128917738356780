import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "../../component/css/delivery_agents.css";
import { IoMdMore } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";
import emptybox from "../../component/images/emptybox.png";

import search from "../../component/images/rewardsearch.png";
import right from "../../component/images/arrow-right.png";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

const AdminAgentReferral = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState(null);
  const [searchString, setSearchString] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const fetchData = async (pageCountM) => {
    try {
      setSearch(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);
      //console.log(resultM.delivery_agents)
      setData(resultM.delivery_agents);
      //console.log(data);
      //console.log('got here')

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten succesfully');
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };
  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "No Data Yet";
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log('got here');
      // setData(await response.json());
      const result = await response.json();
      setData(result.delivery_agents);
      //console.log(result.delivery_agents);
      //console.log(searchString);
      //console.log(result);
      setResult(result);

      if (result.msg === "Success") {
        //console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleRowClick = (delivery_agent) => {
    // console.log(`----------> ${JSON.stringify(delivery_agent)}`); //_id
    navigate(`/App/individual_agent_referrals`, {
      state: { delivery_agent: delivery_agent },
    });
  };

  if (!isLoaded) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader color={"#1FAA08"} size={100} />
      </div>
    );
  } else if (data?.length > 0) {
    return (
      <div>
        <div className="ref-search-ctn">
          <div className="main-search-box-container">
            <input
              type="text"
              placeholder="Search Delivery Agent"
              className="search-box"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button className="delivery-searching">
              <FaSearch
                onClick={() => {
                  fetchSearchData();
                  setSearch(true);
                  setPageCount(1);
                }}
              />
            </button>
          </div>
        </div>
        <table>
          <thead>
            <th>Agent ID</th>
            <th>Full name</th>
            <th>Email address</th>
            <th>Phone number</th>
            <th>Total Earnings</th>
          </thead>

          <tbody>
            {data?.map((item, i) => (
              <tr
                key={i}
                onClick={() => {
                  handleRowClick(item);
                }}
                style={{ cursor: "pointer" }}
              >
                <td>{item?.delivery_agent_code}</td>
                <td className={ClassNameDeterminer(item?.fullname)}>
                  {NameTrimer(DataChecker(item?.fullname))}
                  <p style={{ display: "none" }}>
                    {DataChecker(item?.fullname)}
                  </p>
                </td>
                <td>{item?.email}</td>
                <td>{item?.phone_no}</td>
                <td>{ZeroChecker(item?.total_earnings)}</td>
                {/* <td>
                  <div className="popup-click">
                    {Menu({
                      id: item?._id,
                      name: item?.fullname,
                      code: item?.delivery_agent_code,
                      fleet_manager_code: item?.fleet_manager_code,
                      img: item?.img_url,
                      email: item?.email,
                    })}
                  </div>
                </td> */}

                <td>
                  <img src={right} width="20px" height="auto" alt="icon" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else if (data?.length === 0) {
    return (
      <>
        <div className="align-not-found">
          <div className="none11-found">
            <img src={emptybox} alt="No Users Found" className="no1-found" />
          </div>
          <div className="no-title11">
            No Delivery Agent found at the moment
          </div>
        </div>
      </>
    );
  }
};

export default AdminAgentReferral;
