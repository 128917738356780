import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import "../../component/css/agent_applications.css";

const RewardedUsers = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoaded(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_rewarded_users",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);

      setResult(resultM);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  let DATE = {};
  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.combined;
  };

  if (!isLoaded) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader color={"#1FAA08"} size={100} />
      </div>
    );
  } else if (result.msg === "Success") {
    return (
      <div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Reward</th>
              <th>User Reward Details</th>
              <th>Date Rewarded</th>
            </tr>
          </thead>
          <tbody>
            {result.referrals.map((item, index) => (
              <tr key={index}>
                <td>{item.fullname}</td>
                <td>{"09024727328"}</td>
                {/* <td>{item.phone}</td> */}
                <td>{item.reward_notifier}</td>
                <td>
                  {item.reward_notifier === "cash" ? (
                    <>
                      <div>Account Name: {"Joseph"}</div>
                      <div>Account Number: {"08887326324"}</div>
                      <div>Bank name: {"Kuda bank"}</div>
                    </>
                  ) : (
                    <>
                      <div>Network Provider: {"MTN"}</div>
                      <div>Phone Number: {"08887326324"}</div>
                    </>
                  )}
                </td>
                <td>{<TimeConverter value={item.rewarded_timestamp} />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else if (result.msg === "no rewarded users found") {
    return <p style={{ textAlign: "center" }}>No rewarded users found</p>;
  } else if (
    result.msg === "Account has been blocked, please contact master admin"
  ) {
    return (
      <p style={{ textAlign: "center" }}>
        Account has been blocked, please contact master admin
      </p>
    );
  }
};

export default RewardedUsers;
