import React from 'react'
import emptybox from '../images/emptybox.png';
import '../css/no_blocked.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';

export default function No_Blocked_Fleet() {

    const navigate = useNavigate();

    return (
      <div className='no-blocked'>
          <div className='no-blocked-properties'>
              <div className='back1'>
                  <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => navigate('/App/Fleet_Managers')} className='back'></FontAwesomeIcon>       
              </div>
              <h1 className='no-blocked-title'>Blocked Fleet Managers</h1>
            <div className='align-not-found'>
                <div className='none11-found'>
                    <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
                </div>
                <div className='no-title11'>No Blocked Fleet Managers found at the moment</div>
            </div>
          </div>
      </div>
    )
}
