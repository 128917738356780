import React from 'react'
import '../../component/css/delivery_details.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import profilepic3 from '../../component/images/profilepic3.jpg';
import rectangle4133 from '../../component/images/rectangle4133.png'
import checkoutdelivery from '../../component/images/checkoutdelivery.png';

export default function Delivery_Details() {
  return (
    <div className='delivery-details'>
        <div className='delivery-details-props'>
            <Link to='/App/Dashboard' className='back1'>
                <FontAwesomeIcon icon={faArrowLeftLong} className='back' ></FontAwesomeIcon>       
            </Link>
            <h1 className='instant-delivery-summary-title'>Delivery Summary</h1>
            <h3 className='instant-delivery-title'>Instant Delivery</h3>
            <div className='delivery-acception'>Delivery request accepted by:</div>
            
            <div className='instant-delivery-details'>
                <div className='instant-delivery-agent-image'>
                    <img src={profilepic3} alt='' className='deliveryimage' />
                </div>
                <div className='instant-delivery-agent-details'>
                    Delivery Agent <span className='numbers-details'>22</span> <br />
                    Vehicle Type : <span className='numbers-details'>22</span> <br />
                    Vehicle Color : <span className='numbers-details'>22</span> <br />
                    Agent ID : <span className='numbers-details'>22</span> <br />
                    Plate Number : <span className='numbers-details'>22</span> <br />
                    Phone Number : <span className='numbers-details'>22</span> <br />
                </div>
            </div>
            <div className='delivery-line-breaker'>
                <hr className='delivery-line-break' />
            </div>
            <div className='instant-delivery-details1'>
                <div className='instant-delivery-client-details1'>
                    Delivery ID : <span className='numbers-details1'>22</span> <br />
                    Clients name : <span className='numbers-details1'>22</span> <br />
                    Clients Phone Number : <span className='numbers-details1'>22</span> <br />
                    Receivers Phone Number : <span className='numbers-details1'>22</span> <br />
                    Parcel Name : <span className='numbers-details1'>22</span> <br />
                    Parcel Type : <span className='numbers-details1'>22</span> <br />
                    Item Quantity : <span className='numbers-details1'>22</span> <br />
                    Delivery Instructions : <span className='numbers-details1'>22</span> <br />
                    Scheduled Pickup Date : <span className='numbers-details1'>22</span> <br />
                    Scheduled Pickup Time  : <span className='numbers-details1'>22</span> <br />
                </div>
            </div>
            <div className='delivery-image'>
                <h4 className='delivery-images-title'>Images</h4>
                <div className='delivery-images'>
                    <img src={rectangle4133} className='delivery-images-details' alt='' />
                    <img src={rectangle4133} className='delivery-images-details' alt='' />
                    <img src={rectangle4133} className='delivery-images-details' alt='' />
                </div>
            </div>
            <div className='delivery-line-breaker1'>
                <hr className='delivery-line-break' />
            </div>
            <div>
                <div className="delivery-details-location">
                    <div className="delivery-deatails-location-pickup">
                        <div className="location-img">
                            <img src={checkoutdelivery} alt="" />
                        </div>
                        <div>
                            <div className='pickup-location'>
                                <h3>Pickup Location </h3>
                                <p>5 Noma Street GRA Edo State</p>
                            </div>
                            <div className='delivery-location'>
                                <h3>Delivery loaction </h3>
                                <p>19 Akpakpava Road Benin City Ed...</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
