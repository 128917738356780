import React, { useState } from "react";
import "../css/popup.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function Timeout_Delivery() {
  const navigate = useNavigate();
  const location = useLocation();
  const delivery = location.state.delivery;
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [buttonLoading, setButtonLoading] = useState(false);

  console.log(delivery);

  const ConfirmAction = async () => {
    setButtonLoading(true);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/force_delivery_timeout",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_id: delivery.delivery_id,
            user_id: delivery.sender_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      setButtonLoading(false);
      console.log(result);
      // getData(result.delivery_agent);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        navigate(-1);
      } else {
        console.log("some error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Confirm Timeout Delivery <br />
            </div>
            <div className="popup-rematched">
              Are you sure you want to timeout this<br></br>{" "}
              {delivery.parcel_name} Delivery?
            </div>
          </div>
          <div className="popup-dialog-row">
            <div className="popup-dialog" onClick={() => navigate(-1)}>
              No
            </div>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <div className="popup-dialog1" onClick={ConfirmAction}>
              {buttonLoading ? "..." : "Yes"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
