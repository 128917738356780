import React, { useState } from "react";
import "../css/popup.css";
import { useNavigate, useLocation } from "react-router-dom";
import { db } from "../../utils/firebase";
import { deleteDoc, doc } from "firebase/firestore";

export default function Decline_Agent_Application() {
  const navigate = useNavigate();
  const location = useLocation();
  const delivery_agent_id = location.state.id;
  const type = location.state.type;
  const [data, getData] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const DeclineRegRequest = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_reg_request/decline_request",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result);
      // getData(result.delivery_agent);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        await deleteDoc(doc(db, "delivery_agents", delivery_agent_id));
        navigate("/App/Agent_Applications");
      } else {
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Decline Agent's application <br></br>Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to Decline this <br></br>Agent's
              Application?
            </div>
          </div>
          <div className="popup-dialog-row">
            <div
              className="popup-dialog"
              onClick={() => navigate("/App/Agent_Applications")}
            >
              No
            </div>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <div className="popup-dialog1" onClick={DeclineRegRequest}>
              Yes
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
