import React from "react";
import "../../component/css/profilepix.css";
import profileimage from "../../component/images/profileimage.png";
import aang from "../../component/images/aang.jpg";

export const ProfilePix = (img, fullname, profile, email, phone_no) => {
  //console.log(email, fullname, img);
  //console.log(fullname);
  return (
    <div className="profile-prop">
      <div className="profile-picture">
        <img
          src={img === "a" || img === "" ? aang : img}
          alt="icon"
          className="support-profile-pic"
        />
      </div>
      <div className="profile-text">
        <h6>{fullname}</h6>
        {/* <h6 style={{paddingTop:"6px"}}>{email}</h6> */}
        <h6>{email}</h6>
      </div>
    </div>
  );
};
