import React from 'react'
import '../component/css/logout.css';
// import amico from '../../component/images/amico.png';
import {useNavigate} from 'react-router-dom';

export default function SessionExpired() {
  //console.log('got here');
  const navigate = useNavigate();
  const handleClick = () => {
    // sessionStorage.clear();
    navigate('/');
  }

  return (
    <div className='logout1'>
      <div className='logout-container'>
        <div className='logout-background'>
          <div className='logout-confirmation1'>
            <div className='logout-confirmation-info'><br></br>
              <h4>Session Timeout</h4><br></br><br></br>
              {/* <img src={amico} width='110px' height='110px' alt='icon' /><br></br> */}
              <div className='logout-rematched'>
                <p>Session timed out<br /> <br />
                Please login to continue</p>
              </div>
            </div>
            <div className='logout-dialog-row'>
              {/* <button className='logout-dialog' onClick={() => navigate('/App/Dashboard')}>No</button> */}
              <button className='logout-dialog1' onClick={handleClick}>Yes</button>
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

























// // import React, { useState } from 'react'
// // import '../../component/css/logout.css';



// // export default function Logout () {

// // function App() {
// //   const [popUpMenu, setPopUpMenu] = React.useState(false);
// //   return (
// //     <div className="App">
// //       <button onClick={() => setPopUpMenu(!popUpMenu)}>
// //         Menu with Dropdown
// //       </button>
// //       {popUpMenu && PopUpMenu()}
// //     </div>
// //   );
// // }

// // function PopUpMenu() {
// //   return (
// //     <ul className="drop-down">
// //       <li>Menu-item-1</li>
// //       <li>Menu-item-2</li>
// //       <li>Menu-item-3</li>
// //     </ul>
// //   );
// // }
// //   return(
// //     <div>
// //       <App />
// //     </div>
// //   )

// // }













// import React,  {useContext, createContext, useState} from "react";
// // import '../../component/css/signup.css';
// import DeliveryImage from '../../component/images/DeliveryImage.png';
// import "../../component/css/WelcomeUser.css";
// import { Link, useNavigate, Outlet } from "react-router-dom";
// import { TokenContext  } from '../../component/token_context';
// import Login_Header from "./Login_Header";



// export default function Logout(props) {
//   const navigate = useNavigate()
//   const value = useContext(TokenContext);
//   const {handleformsubmit, message, loginData, setLoginData, token } = value
//   //console.log( "this is the details from",message, loginData, token);
  
//   if (token) {
//     navigate("/App/Dashboard")
//   }

//   const handleChange = (e) => {
//     const target = e.target;
//     const { name, value } = target;
//     setLoginData({ ...loginData, [name]: value });
//   };

  
//   return (
//     <>
//       <div className="login-page">
//         <Login_Header />
      
//         <div className="mainBox" id="welcome-main">
//           <div id="DeliveryImage">
//             <p>
//               Door to Door <span id="yellow">delivery</span>
//               <br /> services for individuals
//               <br /> and businesses.
//             </p>
//             <br />
//             <br />
//             <img src={DeliveryImage} alt="Deliver" />
//           </div>

//           <div id="Form-flex-ULogin">
//             <h2 id="join">Welcome Back Admin</h2>
//             <br />

//             <form className='login-form' onSubmit={handleformsubmit} >
//               <div className='agent-login-input'>
//                 <label className='login-label'>Username</label><br></br>
//                 <input 
//                   type='text' 
//                   value={loginData.username}
//                   onChange={handleChange}
//                   name='username' 
//                   placeholder='Enter you Username' 
//                   className='login-input'                 
//                 />
//               </div>
//               <div className='agent-password'>
//                 <label className='login-label'>Password</label><br></br>
//                 <input 
//                   type='password' 
//                   name='password' 
//                   value={loginData.password}
//                   onChange={handleChange}
//                   className='login-input' 
//                   placeholder='Enter your password'                
//                 />
//               </div>
//               <div className='login-submit'>
//                 <button className='login-btn' type='submit'>login</button>
//                 <div className='message'>{message ? <p> {message}</p> :null} </div>
//               </div>
//             </form>
//           </div>
          
//         </div>        
//       </div>
//       <Outlet />
//     </>
//   );
// }




