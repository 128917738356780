export default function PageCounter(count, pageCount, resultPerPage) {
    //console.log(count, pageCount);
    let page_no = Number.parseInt(count / resultPerPage);
    //console.log(page_no);
    const page_noF = Number.parseFloat(count / resultPerPage);
    //console.log(page_noF);
    if(page_no !== page_noF){
        return pageCount + ' of ' + (page_no + 1);
    } else {
        return pageCount + ' of ' + (page_no + 0);
    }
}