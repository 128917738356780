import React from "react";

const ToggleSwitch = ({ isOn = false, onToggle }) => {
  const handleToggle = () => {
    if (onToggle) onToggle(!isOn); // Toggle based on parent's state
  };

  return (
    <div
      className={`w-12 h-6 flex items-center rounded-full p-1 cursor-pointer ${
        isOn ? "bg-green-500" : "bg-red-500"
      }`}
      onClick={handleToggle}
    >
      <div
        className={`w-4 h-4 bg-white rounded-full shadow-md transform transition-transform ${
          isOn ? "translate-x-6" : "translate-x-0"
        }`}
      ></div>
    </div>
  );
};

export default ToggleSwitch;
