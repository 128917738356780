import React, { useState } from 'react'
import '../../component/css/payment_record.css';
import Agent_Payment_Record  from '../payment_record/Agent_Payment_Record';
import Fleet_Manager_Payment from '../payment_record/Fleet_Manager_Payment';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
// import '../../component/css/toggle.css';
// admin_payment_record fleet_managers



export default function Payment_record() {
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();
    
  const firstClick = () => {
    setToggle(true);

    // navigate("/Pending-del");
  };

  const secondClick = () => {
    setToggle(false);
    // navigate("/Pending-del");
  };

  return (
    <div className='DA-payment-history'>
      <div className='da-payment-props'>
        <div className='da-payment-title'>
          Payment History 
        </div>
        <div className='payment-to'>
          <div className='payment-toggle'
            onClick={firstClick} 
            id={toggle ? "active" : "inactive2"}
          >
            Individual Delivery Agents
          </div>
          <div 
            className='payment1-toggle' 
            onClick={secondClick}
            id={toggle ? "inactive" : "active2"}              
          >
            Fleet Managers
          </div>
          {/* <div><input type='date' name='schedule' min='1970-10-01' className="payment-calender" /></div> */}
        </div>   
        
                
        {
          toggle === true  ? (
            <Agent_Payment_Record
              click={() => {
                navigate("/App/Agent_Payment_Record");
              }}
            />

          ) : toggle === false  ? (
            <Fleet_Manager_Payment
              click2={() => {
                navigate("/App/Fleet_Manager_Payment"); 
              }}
            />
          ) : null
        }
      </div>
    </div>
  )
}
