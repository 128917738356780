import React, { useState, useEffect } from "react";
import "../../component/css/agent_form.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import vehicleimgavatar from "../../component/images/vehicleimgavatar.png";
import aang from "../../component/images/aang.jpg";
import licence from "../../component/images/licence.jpg";
import { ClipLoader } from "react-spinners";
import axios from "axios";

export default function Agent_Form() {
  const [data, getData] = useState([]);
  const location = useLocation();
  const delivery_agent_id = location.state.id;
  const type = location.state.type;
  const navigate = useNavigate();
  //console.log(delivery_agent_id);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [gender, setGender] = useState("male");
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const handleGender = (event) => {
    setGender(event.target.value);
  };

  // State variables for the first set
  const [disableVehicleImagesButton1, setDisableVehicleImagesButton1] =
    useState(false);
  const [gottenVehicleImagesPromoData1, setGottenVehicleImagesPromoData1] =
    useState(null);
  const [vehicle_images_url1, set_vehicle_images_url1] = useState("");
  const [
    is_vehicle_images_thumbnail_changed1,
    set_is_vehicle_images_thumbnail_changed1,
  ] = useState(false);
  const [
    local_vehicle_images_thumbnail_file1,
    set_local_vehicle_images_thumbnail_file1,
  ] = useState(null);
  const [
    local_vehicle_images_thumbnail_src1,
    set_local_vehicle_images_thumbnail_scr1,
  ] = useState("");

  // State variables for the second set
  const [disableVehicleImagesButton2, setDisableVehicleImagesButton2] =
    useState(false);
  const [gottenVehicleImagesPromoData2, setGottenVehicleImagesPromoData2] =
    useState(null);
  const [vehicle_images_url2, set_vehicle_images_url2] = useState("");
  const [
    is_vehicle_images_thumbnail_changed2,
    set_is_vehicle_images_thumbnail_changed2,
  ] = useState(false);
  const [
    local_vehicle_images_thumbnail_file2,
    set_local_vehicle_images_thumbnail_file2,
  ] = useState(null);
  const [
    local_vehicle_images_thumbnail_src2,
    set_local_vehicle_images_thumbnail_scr2,
  ] = useState("");

  // State variables for the third set
  const [disableVehicleImagesButton3, setDisableVehicleImagesButton3] =
    useState(false);
  const [gottenVehicleImagesPromoData3, setGottenVehicleImagesPromoData3] =
    useState(null);
  const [vehicle_images_url3, set_vehicle_images_url3] = useState("");
  const [
    is_vehicle_images_thumbnail_changed3,
    set_is_vehicle_images_thumbnail_changed3,
  ] = useState(false);
  const [
    local_vehicle_images_thumbnail_file3,
    set_local_vehicle_images_thumbnail_file3,
  ] = useState(null);
  const [
    local_vehicle_images_thumbnail_src3,
    set_local_vehicle_images_thumbnail_scr3,
  ] = useState("");

  // State variables for the fourth set
  const [disableVehicleImagesButton4, setDisableVehicleImagesButton4] =
    useState(false);
  const [gottenVehicleImagesPromoData4, setGottenVehicleImagesPromoData4] =
    useState(null);
  const [vehicle_images_url4, set_vehicle_images_url4] = useState("");
  const [
    is_vehicle_images_thumbnail_changed4,
    set_is_vehicle_images_thumbnail_changed4,
  ] = useState(false);
  const [
    local_vehicle_images_thumbnail_file4,
    set_local_vehicle_images_thumbnail_file4,
  ] = useState(null);
  const [
    local_vehicle_images_thumbnail_src4,
    set_local_vehicle_images_thumbnail_scr4,
  ] = useState("");

  // State variables for the fifth set
  const [disableVehicleImagesButton5, setDisableVehicleImagesButton5] =
    useState(false);
  const [gottenVehicleImagesPromoData5, setGottenVehicleImagesPromoData5] =
    useState(null);
  const [vehicle_images_url5, set_vehicle_images_url5] = useState("");
  const [
    is_vehicle_images_thumbnail_changed5,
    set_is_vehicle_images_thumbnail_changed5,
  ] = useState(false);
  const [
    local_vehicle_images_thumbnail_file5,
    set_local_vehicle_images_thumbnail_file5,
  ] = useState(null);
  const [
    local_vehicle_images_thumbnail_src5,
    set_local_vehicle_images_thumbnail_scr5,
  ] = useState("");

  const [disableUpdateVehicleInfo, setUpdateVehicleInfo] = useState(false);

  // State to store the image URLs and any new image files
  const [driver_license_expiry_date, setDriverLicenseExpiryDate] = useState("");
  // Handler to update the expiry date
  const handleDateChange = (e) => {
    setDriverLicenseExpiryDate(e.target.value);
  };
  const [vehicleImgs, setVehicleImgs] = useState([]);
  const [newFiles, setNewFiles] = useState({}); // Store new files by index

  // This function is triggered when admin wants to add a new image
  const addNewVehicleImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      console.log(`----------> ${fileURL}`);

      // Add the new image to the end of the images array
      setVehicleImgs((prevImages) => [...prevImages, fileURL]);

      // Store the new file object in a separate state
      setNewFiles((prevFiles) => ({
        ...prevFiles,
        [vehicleImgs.length]: file, //The new file is added at the next available index in the newFiles state
      }));
    }
  };

  // using this so I can hide the default 'Choose file' button and then use useRef to link my custom button to the input element
  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    // Programmatically click the hidden file input
    fileInputRef.current.click();
  };

  // This function is triggered when a user selects a new image
  const chooseAnotherVehicleImage = (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      console.log(`----------> ${fileURL}`);

      // Update the images to reflect the new image visually
      const updatedImages = [...vehicleImgs];
      updatedImages[index] = fileURL;
      setVehicleImgs(updatedImages);

      // Store the new file object in a separate state
      setNewFiles((prevFiles) => ({
        ...prevFiles,
        [index]: file, // Save the file at the correct index
      }));
    }
  };

  const removeImage = (index) => {
    // Remove the image at the specified index from vehicleImgs
    setVehicleImgs((prevImages) => prevImages.filter((_, i) => i !== index));

    // Remove the file at the specified index from newFiles
    setNewFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[index]; // Remove the file at the index
      return updatedFiles;
    });
  };

  // Helper function to fetch a file from a URL
  const urlToFile = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  };

  const handleUpdatingVehicleImages = async () => {
    setUpdateVehicleInfo(true);
    const bodyFormData = new FormData();

    bodyFormData.append("token", token);
    bodyFormData.append("delivery_agent_id", delivery_agent_id);
    bodyFormData.append(
      "driver_license_expiry_date",
      driver_license_expiry_date
    );

    // Loop through the images and convert URLs to files if needed
    const filePromises = vehicleImgs.map((image, index) => {
      if (newFiles[index]) {
        // If there's a new file at this index, upload the file
        return newFiles[index];
      } else {
        // Otherwise, convert the URL to a file and upload it
        const filename = `image${index + 1}.jpg`; // You can modify this as needed
        return urlToFile(image, filename);
      }
    });

    // Wait for all files to be ready
    const files = await Promise.all(filePromises);

    // Append all files to the FormData
    files.forEach((file, index) => {
      bodyFormData.append("vehicle_details", file); // Use appropriate form field names
      // bodyFormData.append(`vehicle_details[]`, file); // Use appropriate form field names
    });

    // Perform the upload
    axios
      .post(
        "https://serverpickload.wl.r.appspot.com/admin_delivery_agent/edit_vehicle_details",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      .then((response) => {
        setUpdateVehicleInfo(false);

        console.log(`----------> ${response}`);
        if (response.data["msg"] == "success") {
          alert("Successfully updated");
        } else {
          //   showErrorToast(
          //     "oOps! Looks like something went wrong. Please try again"
          //   );
          alert(response.data["msg"]);
        }
      })
      .catch((error) => {
        console.log(`----------> ${error}`);
        // showErrorToast(
        //   "The server encountered an error. Please try again later"
        // );
        setUpdateVehicleInfo(false);
        alert(error);
      });
  };

  let DATE = {};
  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };
  const DateConverter = (props) => {
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
    };
    return DATE.date;
  };

  useEffect(() => {
    fetchData();
    if (data?.vehicle_details?.img_urls) {
      setVehicleImgs(data.vehicle_details.img_urls);
    }
    setDriverLicenseExpiryDate(
      data?.vehicle_details?.driver_license_expiry_date
    );
  }, [isLoaded === false]);

  const fetchData = async () => {
    try {
      //console.log('I am here')
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_reg_request/reg_request",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const resultM = await response.json();
      //console.log(resultM.delivery_agent);
      getData(resultM.delivery_agent);
      setResult(resultM);
      setIsLoaded(true);

      console.log(`-----------> ${JSON.stringify(resultM)}`);

      if (response.status === 200) {
        //console.log("data gotten succesfully");
      } else {
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  if (!isLoaded) {
    //console.log("got here");
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    //console.log("data gotten succesfully");
    return (
      <div className="agent-form">
        <Link to="/App/Agent_Applications" className="back3">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className="back"
          ></FontAwesomeIcon>
        </Link>
        <div className="agent-form-props">
          {/* <div>{JSON.stringify(data)}</div> */}
          <div className="agent-form-title">Personal Information</div>
          <div className="form-details">
            {type != "delivery agent" ? (
              <>
                <label className="agent-form-label">Fleet Name</label>
                <div className="form-information">{data?.fullname}</div>
              </>
            ) : (
              <>
                <label className="agent-form-label">Full Name</label>
                <div className="form-information">{data?.fullname}</div>
              </>
            )}

            <label className="agent-form-label">Email </label>
            <div className="form-information">{data?.email}</div>

            <label className="agent-form-label">Phone number </label>
            <div className="form-information">{data?.phone_no}</div>

            <label className="agent-form-label">Address </label>
            <div className="form-information1">{data?.address}</div>

            <label className="agent-form-label">NIN </label>
            <div className="form-information1">{data?.nin}</div>

            <div className="state-gender">
              <div className="state-row">
                <label className="agent-form-label">State </label>
                <div className="state-information">{data?.state}</div>
              </div>
              <div className="state-row">
                <label className="agent-form-label">City </label>
                <div className="state-information">{data?.city}</div>
              </div>
            </div>

            <div className="state-row">
              <label className="agent-form-label">Gender </label>
              <select
                className="gender-information"
                value={gender}
                onChange={handleGender}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            {/* <label className="agent-form-label">Passport/Selfie photo </label>
            <div className="passport-information">
              {" "}
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="passport"
              />{" "}
            </div> */}

            {type == "delivery agent" ? (
              <>
                <hr className="agent-line"></hr>
                <div className="agent-form-title">Vehicle Information</div>
                <label className="agent-form-label">Delivery medium</label>
                <div className="form-information">
                  {data?.vehicle_details?.type}
                </div>
                <label className="agent-form-label">
                  Vehicle manufacurer/type
                </label>
                <div className="form-information">
                  {data?.vehicle_details?.name}
                </div>
                <label className="agent-form-label">Vehicle color </label>
                <div className="form-information">
                  {data?.vehicle_details?.color}
                </div>
                <label className="agent-form-label">Plate number</label>
                <div className="form-information">
                  {data?.vehicle_details?.plate_no}
                </div>
                {/* <label className="agent-form-label">Driver's licence </label>
                <div className="licence-information">
                </div> */}
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label className="agent-form-label">
                    Vehicle & License Information
                  </label>
                  <div>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }} // Hide the file input
                      onChange={(e) => addNewVehicleImage(e)}
                    />

                    {/* Custom button to trigger file selection */}
                    <button
                      className="add-more-images-button"
                      onClick={handleButtonClick}
                    >
                      +
                    </button>
                  </div>
                </div>

                <div className="vehicle-information">
                  {vehicleImgs.map((url, index) => (
                    <div className="vehicle-information-unit">
                      <img
                        key={index}
                        src={url ? url : vehicleimgavatar}
                        alt="icon"
                        className="vehicle-image"
                        // style={{objectFit: "cover"}}
                      />
                      <div className="vh-buttons-container">
                        <button
                          onClick={() => removeImage(index)}
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "4px",
                          }}
                        >
                          X
                        </button>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg, .gif"
                          onChange={(e) => chooseAnotherVehicleImage(e, index)}
                          className="hide-no-file-chosen"
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div id="vehicle-images-parent">
                  {/* <div>
                    <label className="agent-form-label">
                      Driver's licence expiry date
                    </label>
                    <div className="form-information">
                      {driver_license_expiry_date}
                    </div>
                  </div> */}
                  <div>
                    <label className="agent-form-label">
                      Driver's licence expiry date
                    </label>

                    {/* Display the selected date */}
                    <div className="form-information">
                      {driver_license_expiry_date
                        ? driver_license_expiry_date
                        : "Select drivers license expiry date"}
                    </div>

                    {/* Date picker */}
                    <input
                      type="date"
                      value={driver_license_expiry_date}
                      onChange={handleDateChange}
                      className="date-picker-input"
                    />
                  </div>

                  <button
                    className="vh-update-button"
                    disabled={disableUpdateVehicleInfo}
                    onClick={() => handleUpdatingVehicleImages()}
                  >
                    {disableUpdateVehicleInfo ? (
                      <ClipLoader
                        color={"black"}
                        loading={disableUpdateVehicleInfo}
                        size={15}
                      />
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>

                {/* <div className="vehicle-information">
                  <div>
                  </div>
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[0]
                        ? data?.vehicle_details?.img_urls?.[0]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="licence-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[1]
                        ? data?.vehicle_details?.img_urls?.[1]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="licence-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[2]
                        ? data?.vehicle_details?.img_urls?.[2]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="vehicle-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[3]
                        ? data?.vehicle_details?.img_urls?.[3]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="vehicle-image"
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[4]
                        ? data?.vehicle_details?.img_urls?.[4]
                        : vehicleimgavatar
                    }
                    alt="icon"
                    className="vehicle-image"
                  />
                </div>{" "} */}
              </>
            ) : null}

            <hr className="agent-line"></hr>
            {data?.bank_details?.bank_name != "" ? (
              <>
                <div className="agent-form-title">Bank Details</div>

                <label className="agent-form-label">
                  Bank Account holder's name{" "}
                </label>
                <div className="form-information">
                  {data?.bank_details?.account_name}
                </div>

                <label className="agent-form-label">Bank name </label>
                <div className="form-information">
                  {data?.bank_details?.bank_name}
                </div>

                <label className="agent-form-label">Bank Account number </label>
                <div className="form-information">
                  {data?.bank_details?.account_no}
                </div>

                <label className="agent-form-label">Account type </label>
                <div className="form-information">
                  {data?.bank_details?.account_type}
                </div>

                {/* <label className="agent-form-label">BVN </label>
                <div className="form-information">
                  {data?.bank_details?.bvn}
                </div> */}
              </>
            ) : (
              <div className="agent-form-title">
                Registered under fleet manager. No bank details
              </div>
            )}
            {type != "delivery agent" ? (
              <>
                <hr className="agent-line"></hr>

                <label className="agent-form-label">Company name </label>
                <div className="form-information">{data?.company_name}</div>

                <label className="agent-form-label">
                  CAC Registration Number
                </label>
                <div className="form-information">{data?.cac_reg_no}</div>
              </>
            ) : null}

            <hr className="agent-line"></hr>

            <label className="agent-form-label">Date Registered</label>
            <div className="form-information">
              {<DateConverter value={data?.registration_time} />}
            </div>

            <label className="agent-form-label">Time Registered</label>
            <div className="form-information">
              {<TimeConverter value={data?.registration_time} />}
            </div>

            <div className="accept-form">
              <button
                className="decline"
                onClick={() =>
                  navigate("/App/Decline_Agent_Application", {
                    state: { id: delivery_agent_id, type: type },
                  })
                }
              >
                Decline
              </button>
              <button
                className="accept"
                onClick={() =>
                  navigate("/App/Accept_Agent_Application", {
                    state: { id: delivery_agent_id, gender: gender },
                  })
                }
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
