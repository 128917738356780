import React from 'react';
import {useNavigate} from 'react-router-dom';
import '../css/changed_number.css';
import numbersucessful from '../images/numbersucessful.png';

export default function Changed_Fullname_Successfully() {
  const navigate = useNavigate();  
  setTimeout(() => {
    navigate(-2)
  }, 4000);
  return (
    <div className='change-number'>
      <div className='changed-number-props'>
        <img src={numbersucessful} alt='' className='success-icon-number' />
        <div className='message-success'>Your Fullname has <br></br>been changed successfully</div>
      </div>
    </div>
  )
}
