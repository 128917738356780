import React from 'react'
import '../../component/css/logout.css';
import amico from '../../component/images/amico.png';
import {useNavigate} from 'react-router-dom';

export default function Logout() {
  const navigate = useNavigate();
  const handleClick = () => {
    sessionStorage.clear();
    navigate('/');
  }

  return (
    <div className='logout'>
      <div className='logout-container'>
        <div className='logout-background'>
          <div className='logout-confirmation'>
            <div className='logout-confirmation-info'><br></br>
              Log Out?<br></br><br></br>
              <img src={amico} width='110px' height='110px' alt='icon' /><br></br>
              <div className='logout-rematched'>
                Are you sure you want to logout of your account?
              </div>
            </div>
            <div className='logout-dialog-row'>
              <button className='logout-dialog' onClick={() => navigate('/App/Dashboard')}>No</button>
              <button className='logout-dialog1' onClick={handleClick}>Yes</button>
            </div> 
          </div>
        </div>
      </div>
    </div>
  )
}
