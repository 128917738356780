import React, { useContext, useEffect, useState } from "react";
import "../../component/css/dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faPersonCircleCheck,
  faSackDollar,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";
import scheduleicon from "../../component/images/scheduleicon.png";
import cancelledrideicon from "../../component/images/cancelledrideicon.png";
import { IoMdMore } from "react-icons/io";
import green from "../../component/images/green.png";
import red from "../../component/images/red.png";
import yellow from "../../component/images/yellow.png";
import pink from "../../component/images/pink.png";
import grey from "../../component/images/grey.png";
import blue from "../../component/images/blue.jpg";
import PageCounter from "../../component/page_counter";
import ClipLoader from "react-spinners/ClipLoader";
import emptybox from "../../component/images/emptybox.png";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";
import { TokenContext } from "../../component/token_context";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
  orderBy,
  limit,
  startAfter,
  startAt,
  endBefore,
  endAt,
} from "firebase/firestore";
import { db, storage } from "../../utils/firebase";
import audioFile from "../../audio/new_noti.wav";
import ClickAwayListener from "react-click-away-listener";
// import { TokenContext } from '../logout/Login';

export const DataChecker = (data) => {
  if (data) {
    return data;
  } else {
    return "No Data Yet";
  }
};

export default function Dashboard() {
  const value = useContext(TokenContext);
  const { SetNewDevReq, setNewRep, listen, setListen } = value;
  const [sub, setSub] = useState(true);
  const [data, setData] = useState([]);
  const [stats, setStats] = useState([]);
  const [result, setResult] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);
  const [firstFire, setFirstFire] = useState(true);
  const [count, setCount] = useState(0);
  const navigate = useNavigate("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [last_doc, setLastDoc] = useState({});
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [firstFire2, setFirstFire2] = useState(true);
  const [firstFire3, setFirstFire3] = useState(true);
  const [firstFire4, setFirstFire4] = useState(true);
  const [new_user_cancelled_dev, setNewUserCancelledDev] = useState(false);
  const [new_agent_cancelled_dev, setNewAgentCancelledDev] = useState(false);
  const audio = new Audio(audioFile);
  const audio2 = new Audio(audioFile);
  const audio3 = new Audio(audioFile);
  const audio4 = new Audio(audioFile);

  const [order, setOrder] = useState(null)
  const [status, setStatus] = useState('')

  let time = "";
  // let result = {};
  let timestamp = "";

  // const id = setInterval(() => {
  //   SetNewDevReq(false);
  //   //console.log('interval');
  //   // fetchData();
  //   // // if(!present) {
  //   // //   clearInterval(id);
  //   // // }
  // }, 1000);

  // setTimeout(() => {
  //   //console.log('timeout');
  //   clearInterval(id);
  // }, 10000);

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  useEffect(() => {
    SetNewDevReq(false);
  }, [data]);




  useEffect(() => {
    let i = 0;
    //console.log(sub);
    const devRef = collection(db, "delivery_requests");
    const q = query(devRef, orderBy("timestamp", "desc"), limit(resultPerPage));
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const list = [];
      QuerySnapshot.forEach((doc) => {
        if (
          (doc?.data().search_timed_out === false || doc?.data().search_timed_out === null) &&
          (doc?.data().pay_timed_out === false || doc?.data().pay_timed_out === null) &&
          (doc?.data().all_time_out === false || doc?.data().all_time_out === null) &&
          (doc?.data().user_payment_timed_out === false || doc?.data().user_payment_timed_out === null) &&
          (doc?.data().delivery_status_is_cancelled === false || doc?.data().delivery_status_is_cancelled === null) &&
          (doc?.data().delivery_status_is_started === false || doc?.data().delivery_status_is_started === null) &&
          (doc?.data().timed_out === false || doc?.data().timed_out === null)
        ) {
          list.push(doc?.data());
          console.log();
          
          //console.log(i++);
        }
        setLastDoc(QuerySnapshot.docs[QuerySnapshot.docs.length - 1]);
        //console.log(list[list.length - 1], 'first');
        setData(list);

        // console.log("Filtered list:", list);

        data.forEach((element) => {
          time = new Date(element.timestamp);
          timestamp = time.toLocaleDateString();
        });
      });
    });

    if (data.length > 0) {
      setIsLoaded(true);
    }

    if (sub === false) {
      unsubscribe();
      setSub(true);
    }

    let stat = {};
    const Stat = onSnapshot(
      doc(db, "statistics", "statistics"),
      async (doc) => {
        stat = doc.data();
        setStats(stat);
        //console.log(stats);
        // console.log(`----------> ${JSON.stringify(stat)}`);
      }
    );

    let countM = {};
    const check = onSnapshot(
      doc(db, "admin_notifiers", "delivery_requests"),
      async (doc) => {
        countM = doc.data();
        //console.log(firstFire);
        //console.log(countM);
        if (firstFire == true) {
          setCount(countM.deliveries_count);
          setFirstFire(false);
        } else {
          //console.log('omo');
          if (countM.deliveries_count !== count) {
            setCount(countM.deliveries_count);
            //console.log('played');
            audio.play();
            SetNewDevReq(true);
          }
        }
      }
    );
    let countM2 = {};
    const check2 = onSnapshot(
      doc(db, "admin_notifiers", "reports"),
      async (doc) => {
        countM2 = doc.data();
        if (firstFire2 == true) {
          setCount2(countM2.reports_count);
          setFirstFire2(false);
        } else {
          if (countM2.reports_count !== count2) {
            setCount2(countM2.reports_count);
            //console.log('played');
            audio2.play();
            setNewRep(true);
          }
        }
      }
    );

    let countM3 = {};
    const check3 = onSnapshot(
      doc(db, "admin_notifiers", "cancelled_deliveries_by_user"),
      async (doc) => {
        countM3 = doc.data();
        if (firstFire3 == true) {
          setCount3(countM3.cancelled_count);
          setFirstFire3(false);
        } else {
          if (countM3.cancelled_count !== count3) {
            setCount3(countM3.cancelled_count);
            //console.log('played');
            audio3.play();
            setNewUserCancelledDev(true);
          }
        }
      }
    );

    let countM4 = {};
    const check4 = onSnapshot(
      doc(db, "admin_notifiers", "cancelled_deliveries_by_delivery_agent"),
      async (doc) => {
        countM4 = doc.data();
        if (firstFire4 == true) {
          setCount4(countM4.cancelled_count);
          setFirstFire4(false);
        } else {
          if (countM4.cancelled_count !== count4) {
            setCount4(countM4.cancelled_count);
            //console.log("played");
            audio4.play();
            setNewAgentCancelledDev(true);
          }
        }
      }
    );
  }, [isLoaded === false]);

  const fetchMore = async () => {
    const devRef1 = collection(db, "delivery_requests");
    const q = query(
      devRef1,
      orderBy("timestamp", "desc"),
      startAfter(last_doc),
      limit(resultPerPage)
    );
    const unsub = onSnapshot(q, (QuerySnapshot) => {
      //console.log(QuerySnapshot);
      const list = [];
      QuerySnapshot.forEach((doc) => {
        //console.log('got here');
        //console.log(doc.data());
        if (
          (doc?.data().user_payment_timed_out === false &&
            doc?.data().delivery_status_is_started === false &&
            doc?.data().timed_out === false) ||
          doc?.data().delivery_status_is_cancelled_by === "delivery agent"
        ) {
          //console.log(doc?.data().timed_out);
          list.push(doc?.data());
        }
        //console.log(list[list.length - 1], 'earlier');
        setLastDoc(QuerySnapshot.docs[QuerySnapshot.docs.length - 1]);
        setData(list);
        data.forEach((element) => {
          time = new Date(element.timestamp);
          timestamp = time.toLocaleDateString();
        });
      });
    });
    if (data.length > 0) {
      setIsLoaded(true);
    }
  };

  const fetchEarlier = async () => {
    //console.log('got here')
    const devRef = collection(db, "delivery_requests");
    const q = query(
      devRef,
      orderBy("timestamp", "desc"),
      endBefore(last_doc),
      limit(resultPerPage)
    );
    const unsub = onSnapshot(q, (QuerySnapshot) => {
      //console.log(QuerySnapshot);
      const list = [];
      QuerySnapshot.forEach((doc) => {
        //console.log('got here');
        //console.log(doc.data());
        if (
          (doc?.data().user_payment_timed_out === false &&
            doc?.data().delivery_status_is_started === false &&
            doc?.data().timed_out === false) ||
          doc?.data().delivery_status_is_cancelled_by === "delivery agent"
        ) {
          //console.log(doc?.data().timed_out);
          list.push(doc?.data());
        }
        //console.log(list[list.length - 1], 'later');
        setLastDoc(QuerySnapshot.docs[QuerySnapshot.docs.length - 1]);
        setData(list);
        data.forEach((element) => {
          time = new Date(element.timestamp);
          timestamp = time.toLocaleDateString();
        });
      });
    });
    if (data.length > 0) {
      setIsLoaded(true);
    }
  };

  let DATE = {};

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };
  const DateConverter = (props) => {
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
    };
    return DATE.date;
  };

  const DotChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "...";
    }
  };

  const RevDotChecker = (data) => {
    if (data === 0) {
      return "₦0.00";
    }
    if (data) {
      return "₦" + data.toLocaleString();
    } else {
      return "...";
    }
  };

  const StatusColor = (value) => {
    //console.log(value);
    if (value.is_completed === true) {
      return green;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === false
    ) {
      return yellow;
    }
    if (value.is_accepted === false) {
      return grey;
    }
    if (value.is_cancelled === true) {
      return red;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === true
    ) {
      return pink;
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === true
    ) {
      return blue;
    }
  };
  // background color of hover
  // const Btatus = (value) => {
  //   //console.log(value);
  //   if(value.is_completed === true){
  //     return "green"
  //   } if(value.is_accepted === true && value.is_completed === false && value.is_cancelled === false) {
  //     return "yellow"
  //   } if(value.is_accepted === false && value.is_cancelled === false){
  //     return "orange"
  //   } if (value.is_cancelled === true){
  //     return "red"
  //   }
  // }

  const StatusTooltip = (value) => {
    //console.log(value);
    if (value.is_completed === true) {
      return "Delivery Completed";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === false
    ) {
      return "Delivery Accepted";
    }
    if (value.is_accepted === false) {
      return "Delivery not Accepted";
    }
    if (value.is_cancelled === true) {
      return "Delivery Cancelled";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === true
    ) {
      return "Delivery Picked Up";
    }
    if (
      value.is_accepted === true &&
      value.is_completed === false &&
      value.is_cancelled === false &&
      value.is_started === false &&
      value.is_paid === true
    ) {
      return "Delivery Paid";
    }
  };

  const Nav = (details) => {
    if (details.delivery_type === "instant") {
      navigate("/App/Instant_Delivery", { state: { id: details.delivery_id } });
    } else {
      navigate("/App/Scheduled_Delivery", {
        state: { id: details.delivery_id },
      });
    }
  };

  // const CashConverter = (props) => {
  //   const time = props.value.toLocaleString();
  //   return time;
  // }

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
      fetchEarlier();
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
    fetchMore();
  };

  const fetchData = async (pageCountM) => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/recent_requests",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const resultM = await response.json();
      console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);

      data.forEach((element) => {
        time = new Date(element.timestamp);
        timestamp = time.toLocaleDateString();
        //console.log(timestamp);
      });

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten succesfully');
      }

    } catch (error) {

    }
  };



  const Menu = (details) => (
    <div className="admin-App2">
      <Popup
        trigger={
          <button className="more-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
      // contentStyle={{ padding: '0px', border: 'none' }}
      // arrow={false}
      >
        <ul className="drop-down5">
          <li
            onClick={() =>
              navigate("/App/Assign_Delivery", {
                state: { delivery_details: details },
              })
            }
          >
            Assign Delivery Agent
          </li>
          <li onClick={() => Nav(details)}>View Delivery</li>
          {details?.delivery_status_is_accepted == true &&
            details?.delivery_status_is_cancelled == false &&
            details?.delivery_status_is_completed == false &&
            details?.delivery_status_is_paid == false &&
            details?.delivery_status_is_started == false ? (
            <li
              onClick={() => {
                // Nav(details);
                console.log(JSON.stringify(details));
                navigate("/App/Timeout_Delivery", {
                  state: {
                    delivery: details,
                  },
                });
              }}
            >
              Timeout Delivery
            </li>
          ) : null}
        </ul>
      </Popup>
    </div>
  );

  // function PopUpMenu(props) {
  //   const details = props;
  //   //console.log(details);
  //   //console.log(user_id)
  //   return (
  //     <ul className="drop-down1">
  //       <li onClick={() => navigate('/App/Assign_Delivery', {state:{delivery_details: details}})}>Assign Delivery Agent</li>
  //       <li onClick={() => Nav(details)}>View Delivery</li>
  //     </ul>
  //   );
  // }

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  if (!isLoaded) {
    //console.log('got here');
    //console.log(isLoaded);
    return (
      <h1 className="loading-pages">
        {" "}
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (data?.length >= 1) {
    //console.log(data?.length);
    //console.log('I entered successfully');
    return (
      <div className="dashboard">
        <div className="dashboard-props">
          <div className="dashboard-grid">
            <Link to="/App/All_Deliveries" className="infoItem1">
              <div className="design1">
                <FontAwesomeIcon
                  icon={faCar}
                  className="icons-div"
                ></FontAwesomeIcon>{" "}
              </div>
              <div className="itemTitle">ALL DELIVERIES</div>
              <div className="iterations">
                <span className="noIterations">
                  {DotChecker(stats?.total_deliveries?.toLocaleString())}
                </span>
              </div>
            </Link>
            <Link to="/App/Users" className="infoItem2">
              <div className="design2">
                <img
                  src={cancelledrideicon}
                  width="20px"
                  height="20px"
                  alt="icon"
                  className="icons-div2"
                />
              </div>
              <div className="itemTitle">ALL USERS</div>
              <div className="iterations">
                <span className="noIterations">
                  {DotChecker(stats?.no_of_active_users?.toLocaleString())}
                </span>
              </div>
            </Link>
            <Link to="/App/Delivery_Agents" className="infoItem3">
              <div className="design3">
                <img
                  src={scheduleicon}
                  width="20px"
                  height="20px"
                  alt="icon"
                  className="icons-div2"
                />
              </div>
              <div className="itemTitle">ALL AGENTS</div>
              <div className="iterations">
                <span className="noIterations">
                  {DotChecker(
                    stats?.no_of_active_delivery_agents?.toLocaleString()
                  )}
                </span>
              </div>
            </Link>
            <Link to="/App/Fleet_Managers" div className="infoItem4">
              <div className="design4">
                <FontAwesomeIcon
                  icon={faTruck}
                  className="icons-div"
                ></FontAwesomeIcon>{" "}
              </div>
              <div className="itemTitle">ALL FLEET MANAGERS</div>
              <div className="iterations">
                <span className="noIterations">
                  {stats?.no_of_active_fleet_managers?.toLocaleString()}
                </span>
              </div>
            </Link>
            <Link
              to="/App/User_Cancelled_Deliveries"
              className="infoItem5"
              onClick={() => setNewUserCancelledDev(false)}
            >
              <img
                className={new_user_cancelled_dev ? "new-dev-req" : ""}
                src={red}
                alt="red"
                style={{ height: "20px", width: "20px", display: "none" }}
              />
              <div className="design5">
                <FontAwesomeIcon
                  icon={faPersonCircleCheck}
                  className="icons-div"
                ></FontAwesomeIcon>{" "}
              </div>
              <div className="itemTitle">REQUESTS CANCELLED BY USER</div>
              <div className="iterations">
                <span className="noIterations">
                  {DotChecker(
                    stats?.total_cancelled_deliveries_by_users?.toLocaleString()
                  )}
                </span>
              </div>
            </Link>
            <Link
              to="/App/Agent_Cancelled_Deliveries"
              div
              className="infoItem6"
              onClick={() => setNewAgentCancelledDev(false)}
            >
              <img
                className={new_agent_cancelled_dev ? "new-dev-req" : ""}
                src={red}
                alt="red"
                style={{ height: "20px", width: "20px", display: "none" }}
              />
              <div className="design6">
                {" "}
                <FontAwesomeIcon
                  icon={faPersonCircleCheck}
                  className="icons-div"
                ></FontAwesomeIcon>
              </div>
              <div className="itemTitle6">
                REQUESTS CANCELLED BY AGENT<div className="status2"></div>
              </div>
              <div className="iterations">
                <span className="noIterations">
                  {DotChecker(
                    stats?.total_cancelled_deliveries_by_delivery_agents?.toLocaleString()
                  )}
                </span>
              </div>
            </Link>
          </div>
          <div className="delivery-details">
            <div className="delivery-properties">
              {" "}
              Recent Delivery Requests{" "}
            </div>
            <div className="result-per-page">
              <form>
                Results per page
                <input
                  className="chizys-input"
                  type="number"
                  value={resultPerPage}
                  onChange={(e) => setResultPerPage(e.target.value)}
                />
                <button className="chizys-button" onClick={handleButtonClick}>
                  Done
                </button>
              </form>
            </div>
            <div className="dashboard-table">
              <table>
                <th>Clients Name</th>
                <th>Clients Number</th>
                <th>Agents Name</th>
                <th>Agents Number</th>
                <th>Delivery type</th>
                <th>Delivery Medium</th>
                <th>Date/Time</th>
                <th>Status</th>
                <tbody>
                  {data.map((item, i) => (
                    <tr key={i}>
                      <td
                        className={ClassNameDeterminer(item?.sender_fullname)}
                      >
                        {NameTrimer(DataChecker(item?.sender_fullname))}
                        <p style={{ display: "none" }}>
                          {DataChecker(item?.sender_fullname)}
                        </p>
                      </td>
                      <td>{"+234" + item?.sender_phone_no}</td>
                      <td
                        className={ClassNameDeterminer(
                          item?.delivery_agent_name
                        )}
                      >
                        {NameTrimer(DataChecker(item?.delivery_agent_name))}
                        <p style={{ display: "none" }}>
                          {DataChecker(item?.delivery_agent_name)}
                        </p>
                      </td>
                      <td>{DataChecker(item?.delivery_agent_phone_no)}</td>
                      <td>{item?.delivery_type}</td>
                      <td>{item?.delivery_medium}</td>
                      <td>
                        {<DateConverter value={item?.timestamp} />} {<br />}{" "}
                        {<TimeConverter value={item?.timestamp} />}
                      </td>
                      <td className="shadow-position">
                        <img
                          className="delivery-status"
                          src={StatusColor({
                            is_accepted: item?.delivery_status_is_accepted,
                            is_started: item?.delivery_status_is_started,
                            is_completed: item?.delivery_status_is_completed,
                            is_successful: item?.delivery_status_is_successful,
                            is_cancelled: item?.delivery_status_is_cancelled,
                            is_booked: item?.delivery_status_is_booked,
                            is_paid: item?.delivery_status_is_paid,
                            arrived_pickup_location:
                              item?.delivery_status_arrived_pickup_location,
                            arrived_dropoff_location:
                              item?.delivery_status_arrived_dropoff_location,
                          })}
                          alt="status"
                        />
                        <p>
                          {StatusTooltip({
                            is_accepted: item?.delivery_status_is_accepted,
                            is_started: item?.delivery_status_is_started,
                            is_completed: item?.delivery_status_is_completed,
                            is_successful: item?.delivery_status_is_successful,
                            is_cancelled: item?.delivery_status_is_cancelled,
                            is_booked: item?.delivery_status_is_booked,
                            is_paid: item?.delivery_status_is_paid,
                            arrived_pickup_location:
                              item?.delivery_status_arrived_pickup_location,
                            arrived_dropoff_location:
                              item?.delivery_status_arrived_dropoff_location,
                          })}
                        </p>
                      </td>
                      <td>
                        <div className="popup-click">{Menu(item)}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <div
                style={{
                  height: "20px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "flex-end",
                  paddingRight: "20px",
                  marginTop: "10px",
                }}
              >
                <div>
                  {disable ? (
                    <ClipLoader color={"black"} loading={disable} size={15} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      className={
                        pageCount <= 1 ? "icon-space-less" : "icon-space"
                      }
                      onClick={minusPagec}
                    />
                  )}
                </div>
                <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
                <div>
                  {disable1 ? (
                    <ClipLoader color={"black"} loading={disable1} size={15} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className={
                        data?.length <= resultPerPage - 1
                          ? "icon-space-less"
                          : "icon-space"
                      }
                      onClick={addPagec}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data?.length < 1) {
    //console.log(isLoaded);
    //console.log('data gotten succesfully');
    return (
      <div className="dashboard">
        <div className="dashboard-props">
          <div className="dashboard-grid">
            <Link to="/App/All_Deliveries" className="infoItem1">
              <div className="design1">
                <FontAwesomeIcon
                  icon={faCar}
                  className="icons-div"
                ></FontAwesomeIcon>{" "}
              </div>
              <div className="itemTitle">ALL DELIVERIES</div>
              <div className="iterations">
                <span className="noIterations">
                  {DataChecker(stats?.total_deliveries?.toLocaleString())}
                </span>
              </div>
            </Link>
            <Link to="/App/Users" className="infoItem2">
              <div className="design2">
                <img
                  src={cancelledrideicon}
                  width="20px"
                  height="20px"
                  alt="icon"
                  className="icons-div2"
                />
              </div>
              <div className="itemTitle">ALL USERS</div>
              <div className="iterations">
                <span className="noIterations">
                  {DataChecker(stats?.no_of_active_users?.toLocaleString())}
                </span>
              </div>
            </Link>
            <Link to="/App/Delivery_Agents" className="infoItem3">
              <div className="design3">
                <img
                  src={scheduleicon}
                  width="20px"
                  height="20px"
                  alt="icon"
                  className="icons-div2"
                />
              </div>
              <div className="itemTitle">ALL AGENTS</div>
              <div className="iterations">
                <span className="noIterations">
                  {DataChecker(
                    stats?.no_of_active_delivery_agents?.toLocaleString()
                  )}
                </span>
              </div>
            </Link>
            <Link to="/App/Fleet_Managers" div className="infoItem4">
              <div className="design4">
                <FontAwesomeIcon
                  icon={faTruck}
                  className="icons-div"
                ></FontAwesomeIcon>{" "}
              </div>
              <div className="itemTitle">ALL FLEET MANAGERS</div>
              <div className="iterations">
                <span className="noIterations">
                  {stats?.no_of_active_fleet_managers?.toLocaleString()}
                </span>
              </div>
            </Link>
            <Link
              to="/App/User_Cancelled_Deliveries"
              className="infoItem5"
              onClick={() => setNewUserCancelledDev(false)}
            >
              <img
                className={new_user_cancelled_dev ? "new-dev-req" : ""}
                src={red}
                alt="red"
                style={{ height: "10px", width: "10px", display: "none" }}
              />
              <div className="design5">
                <FontAwesomeIcon
                  icon={faPersonCircleCheck}
                  className="icons-div"
                ></FontAwesomeIcon>{" "}
              </div>
              <div className="itemTitle">REQUESTS CANCELLED BY USER</div>
              <div className="iterations">
                <span className="noIterations">
                  {DotChecker(
                    stats?.total_cancelled_deliveries_by_users?.toLocaleString()
                  )}
                </span>
              </div>
            </Link>
            <Link
              to="/App/Agent_Cancelled_Deliveries"
              div
              className="infoItem6"
              onClick={() => setNewAgentCancelledDev(false)}
            >
              <img
                className={new_agent_cancelled_dev ? "new-dev-req" : ""}
                src={red}
                alt="red"
                style={{ height: "10px", width: "10px", display: "none" }}
              />
              <div className="design6">
                {" "}
                <FontAwesomeIcon
                  icon={faPersonCircleCheck}
                  className="icons-div"
                ></FontAwesomeIcon>
              </div>
              <div className="itemTitle6">
                REQUESTS CANCELLED BY AGENT<div className="status2"></div>
              </div>
              <div className="iterations">
                <span className="noIterations">
                  {DataChecker(
                    stats?.total_cancelled_deliveries_by_delivery_agents?.toLocaleString()
                  )}
                </span>
              </div>
            </Link>
          </div>
          <div className="delivery-details">
            <div className="delivery-properties">
              {" "}
              Recent Delivery Requests{" "}
            </div>
            <div className="found-not">
              <img src={emptybox} alt="not found" />
              <p>No Transaction for This Week</p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}