import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/popup.css';

export default function Reverse_Payment() {
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location);
  const {id, week,steps, month, year, transaction_type, vehicle_type, not_paids, name, code, noti_data} = location.state;
  //console.log(not_paids);
  const paid_reversals = [id];


  return (
    <div className='popup'>
      <div className='popup-container'>
        <div className='popup-background'>      
          <div className='popup-confirmation'>
            <div className='popup-confirmation-info'>
              Reverse payment confirmation
            </div>
            <div className='popup-rematched'>
              Are you sure you want to Reverse this payment?<br></br> {name} with Fleet ID<br></br>{code}?
            </div>
          </div>          
          <div className='popup-dialog-row'>
            <div className='popup-dialog' onClick={() => navigate(-1)}>No</div>
            <div className='middle-line'><hr className='popup-hr'></hr></div>
            <div className='popup-dialog1' onClick={() => navigate('/App/Confirm_Pin', {
              state: {
                id: id,
                week, 
                month,
                year,
                transaction_type: 'fleet',
                not_paids,
                name,
                code,
                transaction_type,
                vehicle_type,
                steps,
                noti_data,
                paid_reversals
              },
            })}>Yes</div>
          </div>
        </div>
      </div>
    </div>
  )
}