import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import weekRangeGetter, { getWeekNumber } from "../../component/weekRanger";
import { ClipLoader } from "react-spinners";

const AgentReferral = ({ choosenReward }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const [cname1, setCname1] = useState("week1");
  const [cname2, setCname2] = useState("week2");
  const [cname3, setCname3] = useState("week2");
  const [cname4, setCname4] = useState("week2");
  const [cname5, setCname5] = useState("week2");
  const [cname6, setCname6] = useState("week2");
  const [Dweek, setDWeek] = useState(1);
  const [day, setDay] = useState(Number.parseInt(dayjs().day()));
  const [month, setMonth] = useState(Number.parseInt(dayjs().month()));
  const [year, setYear] = useState(Number.parseInt(dayjs().year()));
  const [date, setDate] = useState(dayjs(Date.now()).format("YYYY-MM"));
  const [Cweek, setCweek] = useState(1);

  const [selectedPaymentType, setSelectedPaymentType] = useState("daily");

  const setPageOption = (option) => {
    setSelectedPaymentType(option);

    //doing all of this so that the date can reset to the current day when the option is changed
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");

    setYear(year);
    setMonth(month);
    setDay(day);

    setSelectedDate(getCurrentDate()); // Set the selected date to the current date for Day, Month & Year
    setDate(dayjs(Date.now()).format("YYYY-MM")); // Set the selected date to the current date for Month & Year
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(getCurrentDate()); // Default to current date

  useEffect(() => {
    // console.log(`----------> REFRESHING`);
    fetchData();
  }, [selectedPaymentType, Cweek, date, selectedDate]);

  const fetchData = async () => {
    try {
      setIsLoaded(false);

      let endpoint =
        selectedPaymentType === "daily"
          ? "https://serverpickload.wl.r.appspot.com/admin_referral/view_daily_qualified_agents"
          : "https://serverpickload.wl.r.appspot.com/admin_referral/view_weekly_qualified_agents";
      let endpointData =
        selectedPaymentType === "daily"
          ? {
              token: token,
              day: Number.parseInt(day),
              month: Number.parseInt(month),
              year: Number.parseInt(year),
            }
          : {
              token: token,
              week: Number.parseInt(Cweek),
              month: Number.parseInt(month),
              year: Number.parseInt(year),
            };
      const response = await fetch(endpoint, {
        method: "POST",

        body: JSON.stringify(endpointData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);

      setResult(resultM);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(`----------> ${Cweek}`);

  //   const [selectedDate, setSelectedDate] = useState("");
  const [noti_data, setNotiData] = useState("");
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const weekNo = getWeekNumber(year, month, new Date().getDate());
    switch (weekNo) {
      case 1:
        handleClick("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[0]
        );
        break;
      case 2:
        handleClick1("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[1]
        );
        break;
      case 3:
        handleClick2("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[2]
        );
        break;
      case 4:
        handleClick3("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[3]
        );
        break;
      case 5:
        handleClick4("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[4]
        );
        break;
      case 6:
        handleClick6("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[5]
        );
        break;
    }
  }, []);

  const handle_MY_Calender = (e) => {
    const newDate = dayjs(e.target.value).format("YYYY-MM");
    const yearM = Number.parseInt(newDate.slice(0, 4));
    const monthM = Number.parseInt(newDate.slice(5, 7)) - 1;
    setDate(newDate);
    setMonth(monthM);
    setYear(yearM);
    weekRangeGetter(monthM, yearM);
    // console.log(newDate, yearM, monthM);
  };
  const handle_DMY_Calender = (e) => {
    const dateValue = e.target.value; // Format: YYYY-MM-DD
    setSelectedDate(dateValue);

    // Extract day, month, and year
    if (dateValue) {
      const [year, month, day] = dateValue.split("-");

      setYear(year);
      setMonth(month);
      setDay(day);
      //   console.log(`Selected Date: Day: ${day}, Month: ${month}, Year: ${year}`);
    }
  };

  const handleClassName1 = () => {
    setCname1("week1");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[0]
    );
  };
  const handleClassName2 = () => {
    setCname1("week2");
    setCname2("week1");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[1]
    );
  };
  const handleClassName3 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week1");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[2]
    );
  };
  const handleClassName4 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week1");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[3]
    );
  };
  const handleClassName5 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week1");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[4]
    );
  };
  const handleClassName6 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week1");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[5]
    );
  };

  const handleClick = (e) => {
    // console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      setCweek(1);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      setCweek(1);
    }
  };

  const handleClick1 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      setCweek(2);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      setCweek(2);
    }
  };

  const handleClick2 = (e) => {
    //console.log(e);
    if (e === "auto") {
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      setCweek(3);
    } else {
      e.preventDefault();
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      setCweek(3);
    }
  };

  const handleClick3 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      setCweek(4);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      setCweek(4);
    }
  };

  const handleClick4 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      setCweek(5);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      setCweek(5);
    }
  };
  const handleClick6 = (e) => {
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      setCweek(6);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      setCweek(6);
    }
  };

  const initialData = [
    {
      name: "Jude Ozoh",
      phone: "0812843283209",
      reward: "Airtime",
      rewardDetails: {
        fullName: "Jude Ozoh",
        bankAccount: "2210908302",
        bank: "First Bank",
      },
      rewardStatus: "Paid",
    },
    {
      name: "Nino Ola",
      phone: "0812843283209",
      reward: "Airtime",
      rewardDetails: {
        fullName: "Nino Ola",
        bankAccount: "2210908302",
        bank: "Access Bank",
      },
      rewardStatus: "Not Paid",
    },
  ];

  const [data2, setData2] = useState(initialData);
  const [modalData, setModalData] = useState({
    isOpen: false,
    message: "",
    index: null,
  });
  const [selectedReward, setSelectedReward] = useState(null);

  const handleRewardStatusClick = (index) => {
    const item = data2[index];
    const message = `Are you sure you want to reward ${item.name} with "${choosenReward}" Reward?`;

    setModalData({ isOpen: true, message, index });
    setSelectedReward(item.reward); // Set current reward for editing
  };

  const closeModal = () =>
    setModalData({ isOpen: false, message: "", index: null });

  const confirmAction = () => {
    if (modalData.index !== null) {
      setData2((prevData) =>
        prevData.map((item, index) =>
          index === modalData.index
            ? { ...item, reward: selectedReward, rewardStatus: "Paid" }
            : item
        )
      );
    }
    closeModal();
  };

  return (
    <div className="referal-table">
      <div className="referal-btn-ctn1">
        <button
          onClick={() => setPageOption("daily")}
          className={`agent-ref-payment-type ${
            selectedPaymentType === "daily" ? "active" : ""
          }`}
          style={{
            backgroundColor:
              selectedPaymentType === "daily"
                ? "rgba(31, 170, 8, 1)"
                : "transparent",
            color:
              selectedPaymentType === "daily"
                ? "rgba(255, 255, 255, 1)"
                : "rgba(31, 170, 8, 1)",
            padding: "0 2.3rem",
            cursor: "pointer",
            marginRight: "1rem",
            borderRadius: "3px",
            transition: "all 0.3s ease",
          }}
        >
          Daily
        </button>
        <button
          onClick={() => setPageOption("weekly")}
          className={`agent-ref-payment-type ${
            selectedPaymentType === "weekly" ? "active" : ""
          }`}
          style={{
            backgroundColor:
              selectedPaymentType === "weekly"
                ? "rgba(31, 170, 8, 1)"
                : "transparent",
            color:
              selectedPaymentType === "weekly"
                ? "rgba(255, 255, 255, 1)"
                : "rgba(31, 170, 8, 1)",
            padding: "0 2.3rem",
            cursor: "pointer",
            marginRight: "1rem",
            borderRadius: "3px",
            transition: "all 0.3s ease",
          }}
        >
          Weekly
        </button>
      </div>

      <div className="payment-content">
        {selectedPaymentType === "daily" && (
          <div>
            <div className="payment-history">
              <div className="payment-calendar-container">
                <input
                  type="date"
                  name="schedule"
                  className="payment-calendar1"
                  value={selectedDate}
                  onChange={handle_DMY_Calender}
                />
              </div>
            </div>
          </div>
        )}
        {selectedPaymentType === "weekly" && (
          <div>
            <div className="payment-history">
              <div className="payment-calender-container">
                <input
                  type="month"
                  name="schedule"
                  min="2021-12-31"
                  max="2043-01-01"
                  className="payment-calender"
                  value={date}
                  onChange={handle_MY_Calender}
                />
              </div>
              <div className="payment-week">
                <div className="week1date">
                  <div className={cname1} onClick={handleClick}>
                    WEEK 1
                  </div>
                  {/* <p className="week-duration">
                    {weekRangeGetter(month, year)[0]}
                  </p> */}
                </div>

                <div className="week1date">
                  <div className={cname2} onClick={handleClick1}>
                    WEEK 2
                  </div>
                  {/* <p className="week-duration">
                    {weekRangeGetter(month, year)[1]}
                  </p> */}
                </div>

                <div className="week1date">
                  <div className={cname3} onClick={handleClick2}>
                    WEEK 3
                  </div>
                  {/* <p className="week-duration">
                    {weekRangeGetter(month, year)[2]}
                  </p> */}
                </div>

                <div className="week1date">
                  <div className={cname4} onClick={handleClick3}>
                    WEEK 4
                  </div>
                  {/* <p className="week-duration">
                    {weekRangeGetter(month, year)[3]}
                  </p> */}
                </div>

                <div className="week1date">
                  <div className={cname5} onClick={handleClick4}>
                    WEEK 5
                  </div>
                  {/* <p className="week-duration">
                    {weekRangeGetter(month, year)[4]}
                  </p> */}
                </div>
                {/* {weekRangeGetter(month, year)[5] ? (
                  <div className="week1date">
                    <div className={cname6} onClick={handleClick6}>
                      WEEK 6
                    </div>
                    <p className="week-duration">
                      {weekRangeGetter(month, year)[5]}
                    </p>
                  </div>
                ) : null} */}
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        {!isLoaded ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader color={"#1FAA08"} size={100} />
          </div>
        ) : result.msg === "Success" ? (
          <>
            <div className="agent-ref-pop">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Reward notifier</th>
                    <th>Agent Reward details</th>
                  </tr>
                </thead>
                <tbody>
                  {data2.map((item, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRewardStatusClick(index)}
                    >
                      <td>{item.name}</td>
                      <td>{item.phone}</td>
                      <td>{choosenReward}</td>
                      {/* <td>{item.reward_notifier}</td> */}
                      <td>
                        <div className="reward-details">
                          {item.reward_notifier === "cash" ? (
                            <>
                              <div>Account Name: {item.ref_account_name}</div>
                              <div>Account Number: {item.ref_account_no}</div>
                              <div>Bank name: {item.ref_bank}</div>
                            </>
                          ) : (
                            <>
                              <div>
                                Network Provider: {item.service_provider}
                              </div>
                              <div>Phone Number: {item.phone_recharge}</div>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {modalData.isOpen && (
                <div className="reward-overlay">
                  <div className="reward-modal">
                    <h1>Agent Reward</h1>
                    <h2>{modalData.message}</h2>
                    <button className="modal-btn" onClick={closeModal}>
                      No
                    </button>
                    <button className="modal-btn1" onClick={confirmAction}>
                      Yes
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : result.msg === "no qualified daily agents" ||
          result.msg === "no weekly qualified agents" ? (
          <p style={{ textAlign: "center" }}>No qualified agents found</p>
        ) : result.msg ===
          "Account has been blocked, please contact master admin" ? (
          <p style={{ textAlign: "center" }}>
            Account has been blocked, please contact master admin
          </p>
        ) : (
          <p style={{ textAlign: "center" }}>
            Check your internet connection and try again
          </p>
        )}
      </div>
    </div>
  );
};

export default AgentReferral;
