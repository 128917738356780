import React from 'react'
import emptybox from '../images/emptybox.png';
import '../css/no_agents_application.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';

export default function No_Admin() {

  const navigate = useNavigate();
  
  return (
    <div className='no-agents-application'>
      <div className='no-agents-application-properties'>
        <div className='back1'>
            <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => navigate(-1)} className='back'></FontAwesomeIcon>       
        </div>
        <h1 className='no-agents-application-title'> Administrators</h1>
        <div className='align-not-found'>
          <div className='none11-found'>
            <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
          </div>
          <div className='no-title11'>No Administrators Found</div>
        </div>
      </div>
    </div>
  )
}  
