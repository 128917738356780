import React from 'react'
import {Link, useNavigate} from 'react-router-dom';
import emptybox from '../images/emptybox.png';
import '../css/no-all-deliveries.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import mask from '../../component/images/mask.png';
import ClickAwayListener from 'react-click-away-listener';

export default function No_Scheduled_Deliveries() {

    const navigate = useNavigate();

    
  function DeliveriesApp() {
    const [popUpMenu, setPopUpMenu] = React.useState(false);
    return (
      <div className="deliveries-App">
        <button onClick={() => setPopUpMenu(!popUpMenu)} className='deliveries-boom' >
          Scheduled Deliveries <img src={mask} alt='' className='mask' />
        </button>
        {popUpMenu && ( 
          <ClickAwayListener onClickAway={() => setPopUpMenu(false)}>
            {PopUpMenu()}
          </ClickAwayListener>
        )}
      </div>
    );
  }
  
  function PopUpMenu() {
    return (
      <ul className="all-deliveries-drop-down">
        <li onClick={() => navigate('/App/All_Deliveries')}>All Deliveries</li>
        <li onClick={() => navigate('/App/Instant_Deliveries')} >Instant Deliveries</li>
        <li onClick={() => navigate('/App/Scheduled_Deliveries')} >Scheduled Deliveries</li>
        <li onClick={() => navigate('/App/Cancelled_Deliveries')} >Cancelled Deliveries</li>
      </ul>
    );
  }
  
    return (
      <div className='no-blocked'>
          <div className='no-blocked-properties'>
              <div className='back1'>
                  <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => navigate(-1)} className='back'></FontAwesomeIcon>       
              </div>
        <div className='deliveries-top'>
          <h1 className='no-blocked-title'>Scheduled Deliveries</h1>
          <div>
          <div>
            <DeliveriesApp />
          </div>
          </div>
          </div>
          
              <div className='no-blocked-header'>
                  <div className='no-agent-search-box-container'>
                      <input type="text" placeholder='Search Scheduled Deliveries' className='no-search-box' />
                  </div>              
              </div>
              <div className='align-not-found'>
          <div className='none11-found'>
            <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
          </div>
          <div className='no-title11'>No Scheduled Deliveries found at the moment</div>
        </div>
          </div>
      </div>
    )
}
