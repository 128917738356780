import React, { useState, useEffect } from 'react';
import '../../component/css/assign_delivery.css';
import { useNavigate } from 'react-router-dom'
import Pickload_Delivery from './Pickload_Delivery';
import General_Delivery from './General_Delivery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

export default function Assign_Delivery() {
    const [toggle, setToggle] = useState(true);
    const [data, getData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [result, setResult] = useState();
    const navigate = useNavigate();
  
    const firstClick = () => {
      setToggle(true);
    };
   
    const secondClick = () => {
      setToggle(false);
    };
  

    // if(!isLoaded) {
    //     //console.log('got here')
    //     return <h1 className='loading-pages'>Loading.....</h1>
    // }else if(result.msg === 'Success') {
    //     //console.log('data gotten succesfully');

    return (
        <div className='assign-delivery'>                    
            <div className='back1'>
                <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => navigate('/App/Dashboard')} className='back'></FontAwesomeIcon>       
            </div>
            <div className='assign-properties'>
                <div className='assign-switch'>
                    <div className='assign-toggle'
                    onClick={firstClick} 
                    id={toggle ? "active" : "inactive2"}
                >  Pickload Agents</div>

                    <div  
                    className='assign2-toggle' 
                    onClick={secondClick}
                    id={toggle ? "inactive" : "active2"}
                    > General Delivery Agents </div>                
                </div>          

                {
                    toggle === true  ? (
                    <Pickload_Delivery
                        click={() => {
                        navigate("/App/Pickload_Delivery");
                        }}
                    />

                    ) : toggle === false  ? (
                    <General_Delivery
                        click2={() => {
                        navigate("/App/General_Delivery"); 
                        }}
                    />
                    ) : null
                }
                
            </div>
        </div>        
    )
// }
}
