import * as firebase from 'firebase/app';
import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore, FieldValue, serverTimestamp} from "firebase/firestore";
import {getStorage} from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBTClBwsYdbZ6cpd8NwlWf9N6FTLjmXVbc",
  authDomain: "pickload-3aba1.firebaseapp.com",
  databaseURL: "http://pickload-3aba1.firebaseio.com",
  projectId: "pickload-3aba1",
  storageBucket: "pickload-3aba1.appspot.com",
  messagingSenderId: "1094613676907",
  appId: "1:1094613676907:web:f0b5c16ac863f4743c922e",
  measurementId: "G-T7SJRSPCM1"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app)
export {FieldValue, serverTimestamp};

// firebase.initializeApp(firebaseConfig);
// export const db = firebase.firestore();
// export const auth = firebase.auth();

// export default firebase;