import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "../administrator/Add_Admin";
import "../../component/css/administrator.css";
import { IoMdMore } from "react-icons/io";
import pickloadonlylogo from "../../component/images/pickloadonlylogo.png";
import { FaSearch } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import No_Admin from "../../component/notfound/No_Admin";
import PageCounter from "../../component/page_counter";
import {
  ClassNameDeterminer,
  NameTrimer,
  RoleTrimer,
} from "../../component/content_trimer";
// import SessionExpired from '../SessionExpired';
// import ClickAwayListener from 'react-click-away-listener';

export default function Administrator() {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [searchString, setSearchString] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);

  const navigate = useNavigate();

  // useEffect(() => {
  //   navigate('/App/Session_Expired');
  //   // <SessionExpired />;
  // }, [token === null, token === undefined]);

  useEffect(() => {
    //console.log("my JESUS I am yours");
    const fetchData = async (pageCountM) => {
      try {
        setSearch(false);
        const response = await fetch(
          "https://serverpickload.wl.r.appspot.com/master_admin/all_admins",
          {
            method: "POST",

            body: JSON.stringify({
              token: token,
              pageCount: pageCountM || pageCount,
              resultPerPage: resultPerPage,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        // setData(await response.json());
        const resultM = await response.json();
        setResult(resultM);
        setIsLoaded(true);
        //console.log(resultM);
        //console.log(data);

        if (resultM.msg === "Success") {
          //console.log("data gotten succesfully");
          setData(resultM.admins);
          setDisable(false);
          setDisable1(false);
        } else {
          setData("");
          //console.log("some error occurred");
        }
      } catch (error) {
        //console.log(error);
      }
    };
    fetchData();
  }, [isLoaded === false]);

  const handleClick = () => {
    // sessionStorage.clear();
    navigate("/");
  };

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const fetchData = async (pageCountM) => {
    try {
      setSearch(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/master_admin/all_admins",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      //console.log(resultM);
      //console.log(data);

      if (resultM.msg === "Success") {
        //console.log("data gotten succesfully");
        setData(resultM.admins);
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const Menu = (admin_id) => (
    <div className="admin-App">
      <Popup
        trigger={
          <button className="more-details">
            {" "}
            <IoMdMore />{" "}
          </button>
        }
        position="right top"
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        // contentStyle={{ padding: '0px', border: 'none' }}
        // arrow={false}
      >
        <ul className="drop-down">
          <li
            onClick={() =>
              navigate("/App/Change_Admin_Role", { state: { id: admin_id } })
            }
          >
            Change Admin's role
          </li>
          <li
            onClick={() =>
              navigate("/App/Block_Administrator", { state: { id: admin_id } })
            }
          >
            Disable Admin's account
          </li>
          <li
            onClick={() =>
              navigate("/App/Delete_Administrator", { state: { id: admin_id } })
            }
          >
            Delete Admin's account
          </li>
        </ul>
      </Popup>
    </div>
  );

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/admins",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log("got here");
      // setData(await response.json());
      const result = await response.json();
      //console.log(result);
      //console.log(data);
      setResult(result);

      if (result.msg === "Success") {
        setData(result.admins);
        //console.log("data gotten succesfully");
      } else {
        setData("");
        //console.log("some error occurred");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // if(!token) {
  //   //console.log('though non go with me I still will follow');
  //   return navigate('SessionExpired');
  // }

  if (!token) {
    return (
      <div className="logout">
        <div className="logout-container">
          <div className="logout-background">
            <div className="logout-confirmation">
              <div className="logout-confirmation-info">
                <br></br>
                Session Timeout<br></br>
                <br></br>
                {/* <img src={amico} width='110px' height='110px' alt='icon' /><br></br> */}
                <div className="logout-rematched">
                  Session timed out.
                  <br />
                  Do you want to login?
                </div>
              </div>
              <div className="logout-dialog-row">
                {/* <button className='logout-dialog' onClick={() => navigate('/App/Dashboard')}>No</button> */}
                <button className="logout-dialog1" onClick={handleClick}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!isLoaded) {
    //console.log("got here");
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (data?.length > 0) {
    //console.log("data gotten succesfully");

    return (
      <div className="administrator">
        <div className="administrator-properties">
          <div className="administrator-title">Administrator</div>
          <div className="administrator-table">
            <div className="admin-search-box-container">
              <div className="main-search-box-container">
                <input
                  type="text"
                  placeholder="Search Administrators"
                  className="search-box"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
                <button className="delivery-searching">
                  <FaSearch
                    onClick={() => {
                      fetchSearchData();
                      setSearch(true);
                      setPageCount(1);
                    }}
                  />
                </button>
              </div>
              <div>
                <Link to="/App/Add_Admin">
                  {" "}
                  <button className="add-administrator-btn">
                    {" "}
                    + Add Admin
                  </button>{" "}
                </Link>
              </div>
              <div>
                <Link to="/App/Blocked_Admins">
                  {" "}
                  <button className="admin-block">View blocked List</button>
                </Link>
              </div>
              <form>
                Result Per Page
                <input
                  className="chizys-input"
                  type="number"
                  value={resultPerPage}
                  onChange={(e) => setResultPerPage(e.target.value)}
                />
                <button className="chizys-button" onClick={handleButtonClick}>
                  Done
                </button>
              </form>
            </div>
            <table className="admin-table">
              <th>
                <div className="agent-table-left">Picture</div>
              </th>
              <th>Name</th>
              <th>Administrative role</th>
              <th>Email address</th>
              <th>Phone Number</th>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="admin-table-pic">
                        <img
                          src={item?.img ? item?.img : pickloadonlylogo}
                          className="admin-table-pic"
                        />
                      </div>
                    </td>
                    <td className={ClassNameDeterminer(item?.fullname)}>
                      {NameTrimer(item?.fullname)}
                      <p style={{ display: "none" }}>{item?.fullname}</p>
                    </td>
                    <td className={ClassNameDeterminer(item?.role.join())}>
                      {RoleTrimer(item?.role.join())}
                      <p style={{ display: "none" }}>{item?.role.join()}</p>
                    </td>
                    <td>{item?.email}</td>
                    <td>{item?.phone_no}</td>
                    <td>
                      <div className="popup-click">{Menu(item?._id)}</div>{" "}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (data?.length === 0) {
    //console.log("and here");
    return <No_Admin />;
  }
}
