import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import search from "../../component/images/rewardsearch.png";
import right from "../../component/images/arrow-right.png";
import {
  NameTrimer,
  ClassNameDeterminer,
} from "../../component/content_trimer";
import { ClipLoader } from "react-spinners";
import emptybox from "../../component/images/emptybox.png";
import { FaSearch } from "react-icons/fa";

const AdminUserReferral = () => {
  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [searchString, setSearchString] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [disable3, setDisable3] = useState(false);
  //   const [fire, setFire] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);

  const [data, setData] = useState([]);

  const [isChecked, setIsChecked] = useState(false); // Initialize state

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update state based on checkbox change
    setIsLoaded(false);
  };
  //console.log(token);
  // const [user_id, setUserId] = useState('');
  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "...";
      //   return "No Data Yet";
    }
  };

  useEffect(() => {
    fetchData();
  }, [isChecked, isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setDisable3(true);
    // setFire(false);
    setPageCount(1);
  };

  const fetchData = async (pageCountM) => {
    let endpoint;
    if (isChecked === false) {
      endpoint =
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_all_users";
    } else {
      endpoint =
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_unqualified_users";
    }

    try {
      setSearch(false);
      const response = await fetch(endpoint, {
        method: "POST",

        body: JSON.stringify({
          token: token,
          pageCount: pageCountM || pageCount,
          resultPerPage: resultPerPage,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });

      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);
      setData(resultM.referrals);
      //console.log(resultM.users);
      //   setFire(true);

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        setDisable3(false);
        //console.log('data gotten succesfully');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/users",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log('got here');
      // setData(await response.json());
      const result = await response.json();
      setData(result.users);
      //console.log(result);
      //console.log(data);
      setResult(result);

      if (result.msg === "Success") {
        //console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
        setDisable3(false);
      } else {
        setData("");
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const handleRowClick = async () => {
    navigate(`/App/individual_user_referrals`);
    // const response = await fetch(
    //   "https://serverpickload.wl.r.appspot.com/admin_referral/view_user_referrals",
    //   {
    //     method: "POST",

    //     body: JSON.stringify({
    //       token: token,
    //       referral_code: "T7711968",
    //     }),
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json, text/plain, */*",
    //     },
    //   }
    // );
    // // setData(await response.json());
    // const resultM = await response.json();
    // console.log(resultM);
  };

  if (!isLoaded) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader color={"#1FAA08"} size={100} />
      </div>
    );
  } else if (data?.length > 0) {
    return (
      <div>
        <div className="ref-search-ctn">
          {/* <div className="ref-search">
            <img src={search} width="20px" height="auto" alt="icon" />
            <input
              type="text"
              className="reward-search-box"
              placeholder="Search For users"
            />
          </div> */}
          <div className="main-search-box-container">
            <input
              type="text"
              placeholder="Search Users"
              className="search-box"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <button className="delivery-searching">
              <FaSearch
                onClick={() => {
                  fetchSearchData();
                  setSearch(true);
                  setPageCount(1);
                }}
              />
            </button>
          </div>

          <div className="reward-checkbox">
            <input
              type="checkbox"
              checked={isChecked} // Bind state to the checkbox
              onChange={handleCheckboxChange} // Handle change events
              style={{
                width: "18px",
                height: "18px",
                cursor: "pointer",
                accentColor: "rgba(26, 168, 3, 1)", // Sets the ticked color
              }}
            />
            <p>Show unqualified users</p> {/* Display state */}
          </div>
        </div>
        <table>
          <thead>
            <th>Full name</th>
            <th>Phone number</th>
            <th>Total Deliveries</th>
            <th>No of Referrals</th>
          </thead>
          {/* <tbody>
                    {userData.map((user, index) => (
                        <tr key={index} onClick={handleRowClick} style={{ cursor: 'pointer' }}>
                            <td>{user.name}</td>
                            <td>{user.phone}</td>
                            <td className='ref-deliv'>{user.deliveries}</td>
                            <td>{user.referrals}</td>
                            <td>
                                <img
                                    src={right}
                                    width="20px"
                                    height="auto"
                                    alt='icon'
                                />
                            </td>
                        </tr>
                    ))}
                </tbody> */}
          <tbody>
            {data.map((item, i) => (
              <tr
                key={i}
                onClick={handleRowClick}
                style={{ cursor: "pointer" }}
              >
                <td className={ClassNameDeterminer(item?.fullname)}>
                  {NameTrimer(DataChecker(item?.fullname))}
                  <p style={{ display: "none" }}>
                    {DataChecker(item?.fullname)}
                  </p>
                </td>
                <td>{"+234" + item?.phone_no}</td>
                <td>{item?.no_of_completed_deliveries}</td>
                <td>
                  {item?.no_of_qualified_referrals +
                    "/" +
                    item?.no_of_referrals}
                </td>
                <td>
                  <img src={right} width="20px" height="auto" alt="icon" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else if (data?.length === 0 || data == null || data == undefined) {
    return (
      <>
        <div className="align-not-found">
          <div className="none11-found">
            <img src={emptybox} alt="No Users Found" className="no1-found" />
          </div>
          <div className="no-title11">No Users found at the moment</div>
        </div>
      </>
    );
  }
};

export default AdminUserReferral;
