import React, { useState, useEffect } from "react";
import "./referral.css";
import setting from "../../component/images/setbar.png";
import Modal from "react-modal";
import refresh from "../../component/images/refresh.png";
import AgentReferral from "./AgentReferral";
import UserReferral from "./UserReferral";
import { Link } from "react-router-dom";
import AdminAgentReferral from "./AdminAgentReferral";
import AdminUserReferral from "./AdminUserReferral";

const Referral = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeContent, setActiveContent] = useState("user");
  const [selectedFilter, setSelectedFilter] = useState("Qualified/Rewarded");
  const [user_target_downliners, set_user_target_downliners] = useState(5);
  const [user_delivery_orders, set_user_delivery_orders] = useState(5);
  const [da_daily_deliveries, set_da_daily_deliveries] = useState(10);
  const [da_weekly_deliveries, set_da_weekly_deliveries] = useState(50);
  const [selectedRewards, setSelectedRewards] = useState("cash");
  const [choosenReward, setChoosenReward] = useState("cash");
  const [disable, setDisable] = useState(false);

  const handleSaveButtonClick = async (e) => {
    e.preventDefault();

    try {
      setIsLoaded(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_settings/set_reward_settings",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            no_of_referees: user_target_downliners,
            no_completed_orders_per_referee: user_delivery_orders,
            target_daily_deliveries: da_daily_deliveries,
            target_weekly_deliveries: da_weekly_deliveries,
            reward_notifier: selectedRewards,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      //console.log(resultM);
      //console.log(data);
      setResult(resultM);
      setIsLoaded(true);

      if (resultM.msg === "Success") {
        setChoosenReward(selectedRewards);
        //   set_user_target_downliners(resultM.stats.no_of_referees);
        //   set_user_delivery_orders(resultM.stats.no_completed_orders_per_referee);
        //   set_da_daily_deliveries(resultM.stats.target_daily_deliveries);
        //   set_da_weekly_deliveries(resultM.stats.target_weekly_deliveries);
      } else if (
        resultM.msg === "Account has been blocked, please contact master admin"
      ) {
        console.log("Account has been blocked, please contact master admin");
      } else {
        console.log("An error occurred");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleValueChange = (setter, newValue, min, max) =>
    setter(Math.max(min, Math.min(max, newValue)));

    // console.log(`----------> ${JSON.stringify(selectedRewards)}`);

  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  
  // const [data, setData] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const fetchData = async () => {
    try {
      setIsLoaded(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_settings/view_reward_settings",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      //console.log(data);
      setResult(resultM);
      setIsLoaded(true);

      if (resultM.msg === "Success") {
        setSelectedRewards(resultM.stats.reward_notifier);
        setChoosenReward(resultM.stats.reward_notifier);
        set_user_target_downliners(resultM.stats.no_of_referees);
        set_user_delivery_orders(resultM.stats.no_completed_orders_per_referee);
        set_da_daily_deliveries(resultM.stats.target_daily_deliveries);
        set_da_weekly_deliveries(resultM.stats.target_weekly_deliveries);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleButtonClick = (content) => {
    setActiveContent(content);
    setSelectedFilter("Qualified/Rewarded"); // Reset the filter when switching between user and delivery agent
  };

  const handleSelectChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  // Render content based on the active button and dropdown selection
  const renderContent = () => {
    if (selectedFilter === "Qualified/Rewarded") {
      return activeContent === "user" ? (
        <UserReferral choosenReward={choosenReward} />
      ) : (
        <AgentReferral choosenReward={choosenReward} />
      );
    }

    if (activeContent === "user") {
      switch (selectedFilter) {
        case "All":
          return <AdminUserReferral />;
        default:
          return null;
      }
    } else if (activeContent === "delivery agent") {
      switch (selectedFilter) {
        case "All":
          return <AdminAgentReferral />;
        default:
          return null;
      }
    }
  };

  return (
    <div className="referral">
      <div className="referal-main">
        <div className="referal-top">
          <div className="referal-title">
            <h1>
              {activeContent === "delivery agent"
                ? "All Delivery Agents"
                : "All Users"}
            </h1>
          </div>
          <div className="referal-user-agent">
            <div className="referal-btn-ctn">
              {["user", "delivery agent"].map((content) => (
                <button
                  key={content}
                  onClick={() => handleButtonClick(content)}
                  style={{
                    backgroundColor:
                      activeContent === content
                        ? "rgba(31, 170, 8, 1)"
                        : "transparent",
                    color:
                      activeContent === content
                        ? "rgba(255, 255, 255, 1)"
                        : "rgba(31, 170, 8, 1)",
                    padding: "2px 2.3rem",
                    cursor: "pointer",
                    marginRight: "0.4rem",
                    borderRadius: "3px",
                    transition: "all 0.3s ease",
                  }}
                >
                  {content === "user" ? "User" : "Delivery Agent"}
                </button>
              ))}
            </div>
            <div className="reward-all-drop">
              <select onChange={handleSelectChange} value={selectedFilter}>
                <option>Qualified/Rewarded</option>
                <option>All</option>
              </select>
            </div>
          </div>
          <div className="referal-settings">
            <img
              src={setting}
              width="30px"
              height="30px"
              alt="settings icon"
              onClick={openModal}
            />
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Profile Modal"
              className="referal-modal"
              overlayClassName="referal-overlay"
              ariaHideApp={false}
            >
              {isLoaded === false ? (
                <div>Loading...</div>
              ) : (
                <form className="referal-set-pop">
                  <div className="pop-title">
                    <span style={{ color: "red" }} onClick={closeModal}>
                      (x)
                    </span>
                    <p>Reward Settings</p>
                  </div>

                  <>
                    <h4 style={{ marginTop: "16px" }}>REWARD TYPE</h4>
                    <div className="reward-options">
                      {["cash", "airtime", "data"].map((rewardOption) => (
                        <label key={rewardOption} className="square-radio">
                          <input
                            type="radio"
                            name="reward-options"
                            value={rewardOption}
                            checked={selectedRewards === rewardOption}
                            onChange={() => setSelectedRewards(rewardOption)}
                          />
                          <span className="tick-mark">
                            {selectedRewards === rewardOption && "✓"}
                          </span>
                          <div className="reward-label">{rewardOption}</div>
                        </label>
                      ))}
                    </div>
                  </>

                  <h4>USER</h4>
                  <div className="pop-div">
                    <label>Number of target downliners</label>
                    <div className="referal-input-container">
                      <input
                        type="number"
                        min="0"
                        max="500"
                        value={user_target_downliners}
                        onChange={(e) =>
                          handleValueChange(
                            set_user_target_downliners,
                            Number(e.target.value),
                            0,
                            500
                          )
                        }
                        className="referal-input"
                      />
                    </div>
                  </div>
                  <div className="pop-div">
                    <label>Targeted number of delivery orders</label>
                    <div className="referal-input-container">
                      <input
                        type="number"
                        min="0"
                        max="500"
                        value={user_delivery_orders}
                        onChange={(e) =>
                          handleValueChange(
                            set_user_delivery_orders,
                            Number(e.target.value),
                            0,
                            500
                          )
                        }
                        className="referal-input"
                      />
                    </div>
                  </div>
                  <h4>DELIVERY AGENT</h4>
                  <div className="pop-div">
                    <label>Target daily deliveries</label>
                    <div className="referal-input-container">
                      <input
                        type="number"
                        min="0"
                        max="500"
                        value={da_daily_deliveries}
                        onChange={(e) =>
                          handleValueChange(
                            set_da_daily_deliveries,
                            Number(e.target.value),
                            0,
                            500
                          )
                        }
                        className="referal-input"
                      />
                    </div>
                  </div>
                  <div className="pop-div">
                    <label>Target weekly deliveries</label>
                    <div className="referal-input-container">
                      <input
                        type="number"
                        min="0"
                        max="500"
                        value={da_weekly_deliveries}
                        onChange={(e) =>
                          handleValueChange(
                            set_da_weekly_deliveries,
                            Number(e.target.value),
                            0,
                            500
                          )
                        }
                        className="referal-input"
                      />
                    </div>
                  </div>
                  <button disabled={disable} onClick={handleSaveButtonClick}>
                    Save
                  </button>
                </form>
              )}
            </Modal>
            <Link to="/App/rewarded_referrals">
              <img
                src={refresh}
                width="30px"
                height="30px"
                alt="refresh icon"
              />
            </Link>
          </div>
        </div>
        {/*  */}
        <div className="content-container">
          <div className="content-display">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
