import React, { useContext, useState, useEffect } from "react";
import "../../component/css/reports.css";
import aang from "../../component/images/aang.jpg";
import { useNavigate } from "react-router-dom";
import No_AgentReport from "../../component/notfound/No_AgentReport";
import { ClipLoader } from "react-spinners";
import green from "../../component/images/green.png";
import PageCounter from "../../component/page_counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { TokenContext } from "../../component/token_context";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../../utils/firebase";
import audioFile from "../../audio/new_noti.wav";
import dayjs from "dayjs";
const audio = new Audio(audioFile);

export default function Agent_Report() {
  const value = useContext(TokenContext);
  const { setNewRep, listen, adminRole } = value;
  const [toggle, setToggle] = useState(true);
  const [data, getData] = useState([]);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [firstFire, setFirstFire] = useState(true);
  const [count, setCount] = useState(0);
  const [result, setResult] = useState();
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [fire, setFire] = useState(true);
  const [pageCount, setPageCount] = React.useState(1);
  const [resultPerPage, setResultPerPage] = useState(20);
  const allowedRoles = ["Agents Support", "master"];

  useEffect(() => {
    fetchData();
  }, [fire === true]);

  useEffect(() => {
    setNewRep(false);
  }, [isLoaded === false]);
  let DATE = {};

  useEffect(() => {
    let countM = {};
    const check = onSnapshot(
      doc(db, "admin_notifiers", "reports"),
      async (doc) => {
        countM = doc.data();
        if (firstFire == true) {
          setCount(countM.reports_count);
          setFirstFire(false);
        } else {
          if (countM.reports_count !== count) {
            setCount(countM.reports_count);
            //console.log('played');
            audio.play();
            setNewRep(true);
            fetchData();
          }
        }
      }
    );
  }, [isLoaded === true]);

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };

  const DateConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: dayjs(date).format("DD/MM/YYYY"),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.date;
  };

  const Nav = (details) => {
    if (details.delivery_type === "instant") {
      navigate("/App/Instant_Delivery", { state: { id: details._id } });
    } else {
      navigate("/App/Scheduled_Delivery", { state: { id: details._id } });
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setFire(true);
    setPageCount(1);
  };

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const fetchData = async (pageCountM) => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_reports/agents_unresolved",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);
      getData(resultM.reports);
      //console.log(result);
      //console.log(data);

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten successfully');
        setFire(false);
      } else {
        //console.log('some error occurred')
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const resolveReport = async (report_id) => {
    try {
      // setDisable(true);
      //console.log(report_id);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_reports/resolve",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            report_id: report_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      if (response.status === 200) {
        //console.log('data gotten succesfully');
        setFire(true);
      } else {
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const firstClick = () => {
    setToggle(true);

    // navigate("/Pending-del");
  };
  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (
    adminRole?.find((role) => allowedRoles?.includes(role)) == undefined ||
    adminRole?.find((role) => allowedRoles?.includes(role)) == null
  ) {
    return (
      <div className="parent-container-spec">
        <div className="main-container-spec">
          <h3>Unauthorized</h3>
          <br></br>
          <br></br>
          <p>Your account is Unauthorized to access this page</p>
        </div>
      </div>
    );
  } else if (result.msg === "Success") {
    return (
      <div>
        <div className="reports-properties">
          <div className="result-per-page">
            <form>
              Results per page
              <input
                className="chizys-input"
                type="number"
                value={resultPerPage}
                onChange={(e) => setResultPerPage(e.target.value)}
              />
              <button className="chizys-button" onClick={handleButtonClick}>
                Done
              </button>
            </form>
          </div>

          {/* <div className='calender-btn'><input type="date" className="calender-report" min="2000-01-02" /></div> */}
          {data.map((item, i) => (
            <div className="report-information">
              <img
                className={false ? "" : "read"}
                src={green}
                alt="green"
                style={{ height: "10px", width: "10px", display: "none" }}
              />
              <div className="report-details" key={i}>
                <div className="report-top">
                  <div className="individual-report">
                    <img
                      src={
                        item?.delivery_agent_img_url
                          ? item?.delivery_agent_img_url
                          : aang
                      }
                      className="report-pic"
                      alt="icon"
                    />
                    <div className="report-key">
                      <div className="report-name">
                        {item.delivery_agent_name}
                      </div>
                      <div className="report-summary">{item.body}</div>
                    </div>
                  </div>
                  <div className="report-time">
                    {<TimeConverter value={item.timestamp} />} <br />{" "}
                    {<DateConverter value={item.timestamp} />}
                  </div>
                </div>
                <div className="report-btns">
                  <button
                    className="report-click-btn"
                    onClick={() => {
                      navigate("/App/Message_User", {
                        state: { user_details: item },
                      });
                    }}
                  >
                    {" "}
                    Message User
                  </button>
                  <button
                    className="report-click-btn"
                    onClick={() => {
                      navigate("/App/Message_Delivery_Agent", {
                        state: { details: item },
                      });
                    }}
                  >
                    {" "}
                    Message Delivery Agent
                  </button>
                  <button
                    className="report-click-btn"
                    disabled={disable}
                    onClick={() => resolveReport(item._id)}
                  >
                    Mark as Resolved
                  </button>
                  <button
                    className="report-click-btn"
                    onClick={() =>
                      Nav({
                        delivery_type: item.delivery_type,
                        _id: item.delivery_id,
                      })
                    }
                  >
                    {" "}
                    View Delivery
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            height: "20px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
            marginTop: "10px",
          }}
        >
          <div>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                onClick={minusPagec}
              />
            )}
          </div>
          <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
          <div>
            {disable1 ? (
              <ClipLoader color={"black"} loading={disable1} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className={
                  data?.length <= resultPerPage - 1
                    ? "icon-space-less"
                    : "icon-space"
                }
                onClick={addPagec}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "No agent reports available presently") {
    //console.log('and here');
    return <No_AgentReport />;
  }
}
