import React from 'react'
import {Link} from 'react-router-dom'
import emptybox from '../images/emptybox.png';
import '../css/no_users.css';
import {FaSearch} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';

export default function No_Agents() {

  const navigate = useNavigate();

  return (
    <div className='shadow-no-users'>
      <div className='shadow-no-users-properties '>
        {/* <div className='back1'>
          <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => navigate(-1)} className='back'></FontAwesomeIcon>       
        </div> */}
        <h1 className='no-users-title'>Delivery Agents</h1>
        {/* <div className='no-users-header'>
          <div className="no-users-search-box-container">
            <input type="text" placeholder='Search Delivery Agents' className='no-ags-search-box'/><button className='no-searching'><FaSearch /></button>          
            <Link to='/App/Blocked_Agents'> <button className='no-users-block'>View blocked List</button></Link>          
          </div>
        </div> */}
        <div className='align-not-found'>
          <div className='none-found'>
            <img src={emptybox} alt='No agents Found' className='no1-found'  /> 
          </div>
          <div className='no-title11'>No Delivery Agents found at the moment</div>
        </div>
      </div>
    </div>
  ) 
}
