import { createContext, useState } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDoc,
  doc,
  getDocs,
  QuerySnapshot,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "../utils/firebase";
import audioFile from "../audio/new_noti.wav";

// import { useNavigate } from 'react-router-dom';
export const TokenContext = createContext();

export const UseTokenProvider = (props) => {
  //console.log('wsp');
  const [message, setMessage] = useState("");
  // const [token, setToken] = useState();
  const [disable, setDisable] = useState(false);
  const [fire, setFire] = useState(false);
  const [new_msg, setNewMsg] = useState(false);
  const [new_reg_app, setNewRegApp] = useState(false);
  const [new_dev_req, SetNewDevReq] = useState(false);
  const [new_rep, setNewRep] = useState(false);
  const [listen, setListen] = useState(true);
  const [count, setCount] = useState(0);
  const [firstFire, setFirstFire] = useState(true);
  const [count1, setCount1] = useState(0);
  const [firstFire1, setFirstFire1] = useState(true);
  const [count2, setCount2] = useState(0);
  const [firstFire2, setFirstFire2] = useState(true);
  const [count3, setCount3] = useState(0);
  const [firstFire3, setFirstFire3] = useState(true);
  const [adminRole, setAdminRole] = useState([]);
  // let adminRole = [];
  const audio = new Audio(audioFile);
  const audio1 = new Audio(audioFile);
  const audio2 = new Audio(audioFile);
  const audio3 = new Audio(audioFile);

  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });

  const Listener = () => {
    //console.log('eneterd')
    let countM = {};
    const check = onSnapshot(
      doc(db, "admin_notifiers", "agent_applications"),
      async (doc) => {
        countM = doc.data();
        if (firstFire == true) {
          setCount(countM.applications_count);
          setFirstFire(false);
        } else {
          if (countM.applications_count !== count) {
            setCount(countM.applications_count);
            //console.log('played');
            audio.play();
            setNewRegApp(true);
          }
        }
      }
    );

    let countM1 = {};
    const check1 = onSnapshot(
      doc(db, "admin_notifiers", "hf_messages"),
      async (doc) => {
        countM1 = doc.data();
        if (firstFire1 == true) {
          setCount1(countM1.messages_count);
          setFirstFire1(false);
        } else {
          if (countM1.messages_count !== count1) {
            setCount1(countM1.messages_count);
            //console.log("played");
            audio1.play();
            setNewMsg(true);
          }
        }
      }
    );

    let countM2 = {};
    const check2 = onSnapshot(
      doc(db, "admin_notifiers", "reports"),
      async (doc) => {
        countM2 = doc.data();
        if (firstFire2 == true) {
          setCount2(countM2.reports_count);
          setFirstFire2(false);
        } else {
          if (countM2.reports_count !== count2) {
            setCount2(countM2.reports_count);
            //console.log('played');
            audio2.play();
            setNewRep(true);
          }
        }
      }
    );

    let countM3 = {};
    const check3 = onSnapshot(
      doc(db, "admin_notifiers", "delivery_requests"),
      async (doc) => {
        countM3 = doc.data();
        //console.log(firstFire3);
        //console.log(countM3);
        if (firstFire3 == true) {
          setCount3(countM3.deliveries_count);
          setFirstFire3(false);
        } else {
          //console.log('omo');
          if (countM3.deliveries_count !== count3) {
            setCount3(countM3.deliveries_count);
            //console.log('played');
            audio3.play();
            SetNewDevReq(true);
          }
        }
      }
    );
  };
  //console.log('hehe');
  Listener();

  //   const navigate = useNavigate();

  const handleformsubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    try {
      const res = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_auth/login",
        {
          method: "POST",

          body: JSON.stringify({
            username: loginData.username,
            password: loginData.password,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const data = await res.json();
      //console.log(data);

      // setToken(data.token) ;

      if (res.status === 200) {
        //console.log('I am here');
        //console.log(data?.admin?.fullname, data?.admin?.img)
        setAdminRole(data?.admin?.role);
        // adminRole = data?.admin?.role;
        sessionStorage.setItem("userToken", JSON.stringify(data?.token));
        sessionStorage.setItem("admin_id", JSON.stringify(data?.admin?._id));
        sessionStorage.setItem(
          "admin_name",
          JSON.stringify(data?.admin?.fullname)
        );
        sessionStorage.setItem("admin_img", JSON.stringify(data?.admin?.img));
        sessionStorage.setItem("admin_role", JSON.stringify(data?.admin?.role));

        setMessage("Logged In successfully");
        setDisable(false);
        setTimeout(() => {
          setMessage("");
        }, 5000);
        // <App />
        // setToken(token)
        // navigate("/App/Dashboard")
      } else if (data.msg === "All fields must be entered") {
        //console.log('got here');
        setMessage("All fields must be filled");
        setDisable(false);
      } else {
        setMessage("some error occured");
        setDisable(false);
      }
    } catch (error) {
      //console.log(error);
      setDisable(false);
    }
    //console.log(loginData);
  };
  const token = sessionStorage.getItem("userToken");
  const admin_role = sessionStorage.getItem("admin_role");
  const admin_id = sessionStorage.getItem("admin_id");
  //console.log(JSON.parse(sessionStorage.getItem('admin_id')), JSON.parse(sessionStorage.getItem('admin_name')), JSON.parse(sessionStorage.getItem('admin_img')))

  const values = {
    token,
    handleformsubmit,
    token,
    loginData,
    setLoginData,
    setMessage,
    message,
    disable,
    setDisable,
    admin_role,
    adminRole,
    setAdminRole,
    admin_id,
    new_msg,
    setNewMsg,
    new_reg_app,
    setNewRegApp,
    new_dev_req,
    SetNewDevReq,
    new_rep,
    setNewRep,
    fire,
    setFire,
    listen,
    setListen,
  };

  return (
    <TokenContext.Provider value={values}>
      {props.children}
    </TokenContext.Provider>
  );
};

// const [token, setToken] = useState();
// const [loginData, setLoginData] = useState({
//     username: "hyksos92142",
//     password: "ndzJN4",
//     password:"q555Ry"
//     username: "jennieglam452":
//     activity_pin: "UsiQrkUDPB"
//     password: "iduflW"
//     username: "testing640"
//   });
// 12405
