import React, { useState, useEffect } from "react";
import aang from "../../component/images/aang.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import No_Agents from "../../component/notfound/No_Agents";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

export default function General_Delivery() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const [data, setData] = useState("");
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const navigate = useNavigate();
  const [pageCount, setPageCount] = React.useState(1);
  const [searchString, setSearchString] = useState("");
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);
  const delivery_details = location.state.delivery_details;

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const fetchData = async (pageCountM) => {
    try {
      setSearch(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/non-pickload_delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);
      //console.log(result.individual_agents_earnings);
      setData(resultM.delivery_agents);
      //console.log(data);
      //console.log('got here')

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten succesfully');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/non-pickload_delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log('got here');
      // setData(await response.json());
      const resultM = await response.json();
      setData(resultM.delivery_agents);
      setResult(resultM);
      //console.log(resultM);
      //console.log(data);

      if (resultM.msg === "Success") {
        //console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log('some error occurred');
        setDisable(false);
        setDisable1(false);
      }
    } catch (error) {
      //console.log(error);
      setDisable(false);
      setDisable1(false);
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages1">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    return (
      <div>
        <div className="assign-properties">
          <div className="assign-header">
            <div className="assign-search-box-container">
              <input
                type="text"
                placeholder="Search General Delivery Agents"
                className="assign-search-box"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <button className="assign-searching">
                <FaSearch
                  onClick={() => {
                    fetchSearchData();
                    setSearch(true);
                    setPageCount(1);
                  }}
                />
              </button>
            </div>
          </div>
          <form>
            Result Per Page
            <input
              className="chizys-input"
              type="number"
              value={resultPerPage}
              onChange={(e) => setResultPerPage(e.target.value)}
            />
            <button className="chizys-button" onClick={handleButtonClick}>
              Done
            </button>
          </form>

          <table className="agent-table">
            <th>
              <div className="agent-table-left">Picture</div>
            </th>
            <th>Agents ID</th>
            <th>Full name</th>
            <th>Email address</th>
            <th>Phone number</th>
            <tbody>
              {data.map((item, i) => (
                <tr key={i}>
                  <td>
                    <div className="users-table-pic">
                      <img
                        src={item?.img_url ? item?.img_url : aang}
                        alt=""
                        className="users-table-pic"
                        style={{ objectFit: "cover" }}
                      />
                    </div>{" "}
                  </td>
                  <td>{item.delivery_agent_code}</td>
                  <td>{item.fullname}</td>
                  <td>{item.email}</td>
                  <td>{item.phone_no}</td>
                  <td>
                    <button
                      className="unblock"
                      onClick={() =>
                        navigate("/App/Assign_Agent", {
                          state: {
                            delivery_agent_details: item,
                            delivery_details: delivery_details,
                          },
                        })
                      }
                    >
                      Assign Delivery
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            height: "20px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
            marginTop: "10px",
          }}
        >
          <div>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                onClick={minusPagec}
              />
            )}
          </div>
          <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
          <div>
            {disable1 ? (
              <ClipLoader color={"black"} loading={disable1} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className={
                  data?.length <= resultPerPage - 1
                    ? "icon-space-less"
                    : "icon-space"
                }
                onClick={addPagec}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "No pickload delivery agent presently") {
    return <No_Agents />;
  }
}
