import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../component/css/add_admin.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import vector from "../../component/images/vector.png";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import SessionExpired from "../SessionExpired";

export default function Add_Admin() {
  // const [formData, setFormData] = useState({
  //   fullname: "",
  //   email: "",
  //   role:"",
  //   phone_no: ""
  // });
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [phone_no, setPhone_no] = useState("");
  const [disable2, setDisable2] = useState(false);
  const [checked_state, setCheckedState] = useState(new Array(6).fill(false));
  const [role, setRole] = useState([]);
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const [selectedFile, setSelectedFile] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedSrc, setSelectedSrc] = useState(null);
  const [profileImage, setProfileImage] = useState("");

  const [deliveryFiles, setDeliveryFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);

  const onFileChange = (e) => {
    setProfileImage(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState == 2) {
        setSelectedSrc(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    setIsSelected(true);
  };

  // const handleFormSubmit = (e) => {
  //   const target = e.target;
  //   const { name, value } = target;
  //   setFormData({ ...formData, [name]: value });
  //   addAdmin();
  // };

  const handleChange = (roleM, index) => {
    let i = -1;
    //console.log(role, role.length, roleM);
    if (role.length === 0) {
      //console.log('first');
      role.push(roleM);
    } else {
      const present = role.some((element) => {
        i++;
        return element === roleM;
      });
      if (present) {
        //console.log('second');
        role.splice(i, 1);
      } else {
        //console.log('third');
        role.push(roleM);
      }
    }
    //console.log(role);

    const updatedCheckedState = checked_state.map((item, j) => {
      return j === index ? !item : item;
    });
    setCheckedState(updatedCheckedState);
  };

  const addAdmin = async (e) => {
    setDisable2(true);
    const roleM = role.join();
    e.preventDefault();
    //console.log(role);
    const bodyFormData = new FormData();
    bodyFormData.append("email", email);
    bodyFormData.append("fullname", fullname);
    bodyFormData.append("phone_no", phone_no);
    bodyFormData.append("role", roleM);
    bodyFormData.append("img", profileImage);
    // for(let i = 0; i < deliveryFiles.length; i++) {
    //   bodyFormData.append('img', deliveryFiles[i]);
    // }
    try {
      const response = await axios.post(
        "https://serverpickload.wl.r.appspot.com/admin_auth/signup",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // getData(await response.json());
      const result = await response.data;
      //console.log(result);
      // setData(result.fleet_managers_earnings);
      //console.log(data);
      //console.log('got here')

      if (response.status === 200) {
        //console.log('data gotten succesfully');
        window.alert(
          `
            Admin account created successfully.
            Username: ${result.login_details.username},
            Password: ${result.login_details.password},
            Activity pin: ${result.login_details.activity_pin}
          `
        );
        setDisable2(false);
        setTimeout(() => {
          navigate("/App/Administrator");
        }, 2000);
      } else {
        //console.log('some error occurred');
        window.alert("Admin not created");
        setDisable2(false);
      }
    } catch (error) {
      if (error.response.data.msg === `${error.response.data.msg}`) {
        setDisable2(false);
        window.alert(`${error.response.data.msg}`);
        //console.log(error);
      } else {
        setDisable2(false);
        //console.log(error);
      }
    }
  };
  return (
    <div className="add-admin">
      <div className="add-admin-properties">
        <Link to="/App/Administrator" className="back2">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            className="back"
          ></FontAwesomeIcon>
        </Link>
        <div className="add-admin-title">Add new administrator</div>
        <form className="add-admin-form-page" onSubmit={addAdmin}>
          *Full Name <br></br>
          <input
            type="text"
            name="fullname"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            className="add-admin-form"
          />
          <br></br>
          *Phone Number <br></br>
          <input
            type="text"
            name="phone_no"
            value={phone_no}
            maxLength={11}
            onChange={(e) => setPhone_no(e.target.value)}
            className="add-admin-form"
          />
          <br></br>
          *Email Address:<br></br>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="add-admin-form"
          />
          <br></br>
          *Administrative Role:<br></br>
          <label>
            <input
              type="checkbox"
              value="Master Admin"
              className="role-checkbox"
              checked={checked_state[0]}
              onChange={() => handleChange("master", 0)}
            />
            Master Admin
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Operation Manager"
              className="role-checkbox"
              checked={checked_state[1]}
              onChange={() => handleChange("Operation Manager", 1)}
            />
            Operation Manager
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Agents Support"
              className="role-checkbox"
              checked={checked_state[2]}
              onChange={() => handleChange("Agents Support", 2)}
            />
            Agents Support
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Finance Manager"
              className="role-checkbox"
              checked={checked_state[3]}
              onChange={() => handleChange("Finance Manager", 3)}
            />
            Finance Manager
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Users Customer care"
              className="role-checkbox"
              checked={checked_state[4]}
              onChange={() => handleChange("Users Customer care", 4)}
            />
            Users Customer care
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Marketing and branding team"
              className="role-checkbox"
              checked={checked_state[5]}
              onChange={() => handleChange("Marketing and branding team", 5)}
            />
            Marketing and branding team
          </label>
          <br></br>
          Upload administrator profile image <br></br>
          <div className="Upload" id="vector">
            <label>
              <img src={vector} alt="Vector" />
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                name="profileImage"
                // disabled={fileLimit}
                onChange={onFileChange}
                multiple
              />
            </label>
          </div>
          <div className="Selected-file-div">
            {isSelected ? (
              <img
                src={selectedSrc}
                style={{ width: "auto", height: "100px" }}
              />
            ) : null}
          </div>
          max file size of 5mb is allowed.<br></br>
          <button className="add-admin-done" type="submit">
            {disable2 ? (
              <ClipLoader color={"black"} loading={disable2} size={15} />
            ) : (
              "Done"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

// Sound should be added when a message/notification comes in

// Help and feedback still buggy... Can't start a fresh conversation and also doesn't scrool automatically to the end once new messages comes in

// * A way to avoid paying delivery agents for weeks that he/she have been paid before (edited)

// *Responsiveness on the admin website has to be worked upon also
// add search for delivery agents and other profile

// - Add "time" to All Deliveries page and also see if it's possible to adjust the columns for names that might be extremely long
// - Payment History page is very unstable, data shows and goes away most times
// - Thumbnails when images are selected
// - When adding new admins. Emails are not displaying in full
// - Admins roles when more than one doesn't show well
// - Maxlines on the chat and also, the text when sent enters the textfield
// - Chat with admin through web is not quite instant, messages take time to enter
