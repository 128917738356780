import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import emptybox from '../images/emptybox.png';
import '../css/no_report.css';
import User_Report from '../../pages/reports/User_Report';
import User_Resolved from '../../pages/reports/User_Resolved';

export default function No_Users_Report() {
    const [toggle, setToggle] = useState(true);
    const [data, getData] = useState([]);
    const navigate = useNavigate();
  
    
    const firstClick = () => {
        setToggle(true);
    
        // navigate("/Pending-del");
    };
   
    const secondClick = () => {
      setToggle(false);
      // navigate("/Pending-del");
    };

  return (

             
    <div className='align-not-found'>
      <div className='none11-found'>
        <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
      </div>
      <div className='no-title11'>No User Report found at the moment</div>
    </div>

  )
}
