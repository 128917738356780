import React from 'react';

const AgentReferralTarget = () => {
  return (
    <div>
      <table>
        {/* Table Caption */}
        <caption>Agent Referral Target Details</caption>

        {/* Table Head */}
        <thead>
          <tr>
            <th>ID</th>
            <th>Sender Name</th>
            <th>Phone Number</th>
            <th>Pick-Up Time</th>
            <th>Drop-Off Time</th>
          </tr>
        </thead>

        {/* Table Body */}
        <tbody>
          <tr>
            <td>6539487</td>
            <td>Michael Okoro</td>
            <td>09069469010</td>
            <td>10:50 AM</td>
            <td>1:50 PM</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AgentReferralTarget;
