import React, { useState, useEffect } from "react";
import "../../component/css/reports.css";
import profilepic2 from "../../component/images/profilepic2.jpg";
import { useNavigate } from "react-router-dom";
import No_Report from "../../component/notfound/No_Report";
import Agent_Report from "./Agent_Report";
import Agent_Resolved from "./Agent_Resolved";
import No_AgentReport from "../../component/notfound/No_AgentReport";
import { ClipLoader } from "react-spinners";

export default function Agent_Reporttoggle() {
  const [toggle, setToggle] = useState(true);
  const [data, getData] = useState([]);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [result, setResult] = useState();

  const firstClick = () => {
    setToggle(true);

    // navigate("/Pending-del");
  };

  const secondClick = () => {
    setToggle(false);
    // navigate("/Pending-del");
  };
  let DATE = {};

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.time;
  };

  // const fetchData = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://serverpickload.wl.r.appspot.com/admin_reports/agents_unresolved",
  //       {
  //         method: "POST",

  //         body: JSON.stringify({
  //           token: token,
  //           pageCount: 1
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json, text/plain, */*"
  //         },
  //       }
  //     )
  //     // getData(await response.json());
  //     const resultM = await response.json();
  //     //console.log(resultM);
  //     setResult(resultM)
  //     setIsLoaded(true)
  //     getData(resultM.reports);
  //     //console.log(result);
  //     //console.log(data);

  //   } catch(error) {
  //     //console.log(error);
  //   }
  // }

  setTimeout(() => {
    setIsLoaded(true);
  }, 3000);

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else {
    return (
      <div>
        <div className="reports-switch">
          <div
            className="report4-toggle"
            onClick={firstClick}
            id={toggle ? "active" : "inactive2"}
          >
            {" "}
            Agents Unresolved Reports
          </div>
          <div
            className="report5-toggle"
            onClick={secondClick}
            id={toggle ? "inactive" : "active2"}
          >
            {" "}
            Agents Resolved Reports
          </div>
        </div>

        {toggle === true ? (
          <Agent_Report
            click={() => {
              navigate("/App/Agent_Report");
            }}
          />
        ) : toggle === false ? (
          <Agent_Resolved
            click2={() => {
              navigate("/App/Agent_Resolved");
            }}
          />
        ) : null}
      </div>
    );
  }
  // else if (result.msg === 'No agent reports available presently'){
  //     //console.log('and here');
  //     return (<No_AgentReport />)
  // }
}
