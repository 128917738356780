import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../component/css/individual_fleet_payment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import aang from "../../component/images/aang.jpg";
import No_Payment from "../../component/notfound/No_Payment";

// Utility functions
const DataChecker = (data) => {
  return data === "nil" ? "No Data Yet" : data || "No Data Yet";
};

const ZeroChecker = (cash) => {
  if (cash === undefined || cash === null || isNaN(cash)) {
    return "₦0.00";
  }
  return `₦${cash === 0 ? 0 : cash.toLocaleString()}`;
};

export default function Individual_Fleet_Payment() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [result, setResult] = useState();
  const [type, setType] = useState("bike");
  const [pageCount, setPageCount] = useState(1);
  const [resultPerPage, setResultPerPage] = useState(20);

  const navigate = useNavigate();

  const week = location.state.week;
  const month = location.state.month;
  const year = location.state.year;
  const fleet_manager_id = location.state.id;
  const status = location.state.status;

  useEffect(() => {
    fetchData();
  }, [type]);

  const TimeConverter = (props) => {
    const date = new Date(props.value);
    return date.toLocaleDateString();
  };

  const DayConverter = (day) => {
    const date = new Date(day).getDay();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[date];
  };

  const handleVehicleTypeChange = (vehicleType) => {
    setIsLoaded(false);
    setType(vehicleType);
    fetchData(vehicleType);
  };

  const StatusDeterminer = () => (status === "paid" ? "paid-logo" : "not-paid-logo");
  const TextDeterminer = () => (status === "paid" ? "PAID" : "NOT-PAID");
  const minusPagec = () => {
    if (pageCount <= 1) return;
    setPageCount((prev) => prev - 1);
    fetchData(pageCount - 1);
  };

  const addPagec = () => {
    if (data?.trx?.length <= resultPerPage - 1) return;
    setPageCount((prev) => prev + 1);
    fetchData(pageCount + 1);
  };

  const fetchData = async (currentPage = pageCount) => {
    try {
      const response = await fetch("https://serverpickload.wl.r.appspot.com/admin_payment_record/fleet_manager", {
        method: "POST",
        body: JSON.stringify({
          token,
          fleet_manager_id,
          week,
          month,
          year,
          vehicle_type: type,
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });
      const resultM = await response.json();

      if (response.ok && resultM?.trx) {
        setData(resultM);
        // console.log(resultM);
        setIsLoaded(true);
      } else {
        throw new Error("No data found or failed to fetch");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoaded(true);
    }
  };

  if (!isLoaded) {
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  }

  if (data?.msg === "Delivery agents weekly transactions retrieved successfully" && data?.trx.length > 0) {
    return (
      <div className="individual-fleet-payment">
        <div className="individual-fleet-details">
          <div className="back1">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            />
          </div>
          <div className="week-no">
            Week {week}
            <div className={StatusDeterminer()}>{TextDeterminer()}</div>
          </div>

          <div className="fleet-details">
            <div className="fleet-profile">
              <img
                src={data?.fleet_manager?.img_url || aang}
                alt="user"
                className="fleet-image"
              />
            </div>
            <div className="fleet-about">
              <span>{data?.fleet_manager?.fleet_name}</span>
              <br />
              <span>Fleet ID: </span>{data?.fleet_manager?.fleet_manager_code}
              <br />
              <span>Phone number: </span>{data?.fleet_manager?.phone_no}
              <br />
              <span>Fleet total earnings: </span>{ZeroChecker(data?.total_weeekly_earnings)}
              <br />
            </div>

            <div className="fleet-payment">
              <span>ACCOUNT DETAILS</span>
              <br />
              <span>Account name: </span>{data?.fleet_manager?.bank_details?.account_name}
              <br />
              <span>Account number: </span>{data?.fleet_manager?.bank_details?.account_no}
              <br />
              <span>Account type: </span>{data?.fleet_manager?.bank_details?.account_type}
              <br />
              <span>Bank name: </span>{data?.fleet_manager?.bank_details?.bank_name}
            </div>
          </div>

          {/* Vehicle Type Filter */}
          <div className="fleet-vehicle-payment">
            {["bike", "car", "van", "truck"].map((vehicle, index) => (
              <div
                key={index}
                className={type === vehicle ? "active" : ""}
                onClick={() => handleVehicleTypeChange(vehicle)}
              >
                {vehicle.toUpperCase()}
              </div>
            ))}
          </div>

          <div className="fleet-table">
            <table>
              <thead>
                <tr>
                  <th>Delivery Agent</th>
                  <th>Delivery Agent ID</th>
                  <th>Date</th>
                  <th>Weekday</th>
                  <th>Daily earnings</th>
                </tr>
              </thead>
              <tbody>
                {data?.trx?.map((item, i) => (
                  <tr key={i} className="payment-data">
                    <td>{item?.delivery_agent_name}</td>
                    <td>{item?.delivery_agent_id}</td>
                    <td><TimeConverter value={item.timestamp} /></td>
                    <td>{DayConverter(item.timestamp)}</td>
                    <td>{ZeroChecker(item?.amt_for_delivery_agent)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return <No_Payment />;
}
